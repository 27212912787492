import { EventsCarousel, SectionsMenu } from "../../components";
import styles from "./styles.module.css";

function CoursesEvents() {
  return (
    <div className={styles.coursesEventsWrapper}>
      <div className={styles.courseEventsBg}>
        <div className={styles.courseEventsTitles}>
          <h3>Upgrade your Skills</h3>
          <h3>to Architect your Future</h3>
        </div>
      </div>
      <SectionsMenu />
      <div className={styles.courseEventsContent}>
        <p>
          Throughout the year, we organize workshops and events to develop our
          participants’ 21st century skills, by enriching and expanding their
          knowledge regarding the concept of data, the types of data, machine
          learning and AI models.
        </p>
      </div>
      <EventsCarousel />
    </div>
  );
}

export default CoursesEvents;
