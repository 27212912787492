import { useParams, useHistory } from "react-router-dom";
import { SectionsMenu } from "../../components";

import styles from "./styles.module.css";
import ventures from "../../data-constants/ventures";

const Venture = (props) => {
  const { vid } = useParams();
  const history = useHistory();

  const venture = ventures.find((v) => v.id === vid);

  if (!venture) {
     history.replace("/");
     return null;
  }

  return (
    <div className={styles.container}>
      <img
        src={venture.heroImage}
        className={styles.heroImg}
        alt={venture.heroAltText}
      />
      <div className={styles.contentContainer}>
        <div style={{marginBottom: 30}}>
          <SectionsMenu venture={vid}/>
        </div>
        {venture.headline && <p className={styles.headline}>{venture.headline}</p>}
        <p className={styles.description}>{venture.description}</p>
        <img
          src={venture.workflowImage}
          className={styles.heroImg}
          alt={venture.workflowAltText}
        />
        <a href={venture.downloadLink} className={styles.download} download={venture.downloadFileName}>
          Download for more information
        </a>
      </div>
      <div className={styles.discussContainer}>
        <p className={styles.discuss}>To discuss your project</p>
        <p className={styles.contact}>Please contact us at</p>
        <a href="mailto:info@aizoh.com" className={styles.boldGreen}>
          info@aizoh.com
        </a>
      </div>
    </div>
  );
};

export default Venture;
