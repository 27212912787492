import { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Logo, Hamburger } from "../../images";
import { Link } from "react-router-dom";
import { NavigationContext } from "../../contexts/navigation-context";
// import {
//   Accordion,
//   AccordionItem,
//   div,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

function Header() {
  const [reduceHeaderSize, setReduceHeaderSize] = useState(false);

  const { isNavigatorVisible, setNavigatorVisibility } = useContext(
    NavigationContext
  );

  useEffect(() => {
    window.addEventListener("scroll", logScroll);
    return () => {
      window.removeEventListener("scroll", logScroll);
    };
  }, []);

  const logScroll = (e) => {
    if (
      document.body.scrollTop >= 100 ||
      document.documentElement.scrollTop >= 100
    ) {

      setReduceHeaderSize(true);
    } else {
      setReduceHeaderSize(false);
    }
  };

  return (
    <header>
      <div
        className={styles.headerWrapperReduced
          // reduceHeaderSize ? styles.headerWrapperReduced : styles.headerWrapper
        }
      >
        <Link to="/">
          <div className={styles.logoWrapper}>
            <img src={Logo} alt="logo" />
            {/* <h4>Aizoh</h4> */}
          </div>
        </Link>
        <div className={styles.insights}>
          <div className={styles.accordionStyles}>
            <div className={styles.accordion}>
              <div className={styles.accItem}>
                <div className={styles.accHeading}>
                  <div className={styles.accBtn}>
                    About us
                  </div>
                </div>
                <div className={styles.accPanel}>
                  <Link to="/mission-vision">
                    <div className={styles.accBtnInner}>
                      Mission & Vision
                    </div>
                  </Link>
                  <Link to="/who-we-are">
                    <div className={styles.accBtnInner}>
                      Who we are?
                    </div>
                  </Link>
                </div>
              </div>
              <div className={styles.accItem}>
                <div className={styles.accHeading}>
                  <div className={styles.accBtn}>
                    Our Work
                  </div>
                </div>
                <div className={styles.accPanel}>
                  <Link to="/what-we-do">
                    <div className={styles.accBtnInner}>
                      What we do?
                    </div>
                  </Link>
                  <Link to="/our-projects">
                    <div className={styles.accBtnInner}>
                      Our projects
                    </div>
                  </Link>
                </div>
              </div>
              <div className={styles.accItem}>
                <div className={styles.accHeading}>
                  <div className={styles.accBtn}>
                    Our Ventures
                  </div>
                </div>
                <div className={styles.accPanel}>
                  <Link to="/venture/ai-eye">
                    <div className={styles.accBtnInner}>
                      Ai Eye
                    </div>
                  </Link>
                  <Link to="/venture/xplorbee">
                    <div className={styles.accBtnInner}>
                      Xplor Bee
                    </div>
                  </Link>
                  <Link to="/venture/recpate">
                    <div className={styles.accBtnInner}>
                      Recapte
                    </div>
                  </Link>
                </div>
              </div>
              <div className={styles.accItem}>
                <div className={styles.accHeading}>
                  <div className={styles.accBtn}>
                    Newsroom
                  </div>
                </div>
                <div className={styles.accPanel}>
                  <Link to="/latest-news">
                    <div className={styles.accBtnInner}>
                      Latest News - AI
                    </div>
                  </Link>
                  <Link to="/news&events">
                    <div className={styles.accBtnInner}>
                      Our News & Events
                    </div>
                  </Link>
                </div>
              </div>
              <div className={styles.accItem}>
                <Link to="/contact">
                  <div className={styles.accHeading}>
                    <div className={styles.accBtn}>
                      Contact Us
                    </div>
                  </div>
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
        <img
          src={Hamburger}
          alt="hamburger"
          className={styles.hamburger}
          onClick={() => setNavigatorVisibility(!isNavigatorVisible)}
        />
      </div>
    </header>
  );
}

export default Header;
