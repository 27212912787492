import "./styles.module.css";

function DefaultButton({ btnName, style }) {
  return (
    <div>
      <button style={style}>{btnName}</button>
    </div>
  );
}

export default DefaultButton;
