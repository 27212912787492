import styles from "./index.module.css";
function SolutionsBanner({ heading, solutionContent }) {
  return (
    <div className={styles.bannerContent}>
      <h1>{heading}</h1>
      <p>{solutionContent}</p>
    </div>
  );
}

export default SolutionsBanner;
