import { Link } from "react-router-dom";
import Office from "../../images/contact/workshopCarousel.jpg";
import styles from "./styles.module.css";
import Carousel from "react-multi-carousel";


function EventsCarousel() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className={styles.ContactInfoWrapper}>
      <Carousel
        additionalTransfrom={0}
        centerMode={false}
        arrows={false}
        swipeable={false}
        renderButtonGroupOutside={false}
        dotListClass={styles.dotStyles}
        responsive={responsive}
        infinite={true}
        autoPlay={false}
      >
        {/* <div className={styles.eventAddress}>
          <img src={Office} alt="office" />
          <div className={styles.eventDetails}>
            <h3>
              Workshop on
              <br /> <span>Artificial Intelligence and Data Science</span>
            </h3>
            <p>
              Date: July 1st,
              <br /> 2021 Time: 12:50 EST
            </p>
            <p>
              100% Digital <br />
              First Edition
            </p>
          </div>
        </div> */}
        {/* <div className={styles.eventAddress}>
          <img src={Office} alt="office" />
          <div className={styles.eventDetails}>
            <h3>
              Workshop on
              <br /> <span>Artificial Intelligence & Nephrology 2021</span>
            </h3>
            <p>
              Date: July 7st,
              <br /> 2021 Time: 12:50 EST
            </p>
            <p>
              100% Digital <br />
              First Edition
            </p>
          </div>
        </div> */}
        <Link to="/workshop">
        <div className={styles.eventAddress}>
          <img src={Office} alt="office" />
          {/* <div className={styles.eventDetails}>
            <h3>
              Workshop on
              <br /> <span>Artificial Intelligence and Big Data</span>
            </h3>
            <p>
              Date: July 1st,
              <br /> 2021 Time: 12:50 EST
            </p>
            <p>
              100% Digital <br />
              First Edition
            </p>
          </div> */}
        </div>
        </Link>
      </Carousel>
    </div>
  );
}
export default EventsCarousel;
