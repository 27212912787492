import styles from "./styles.module.css";
import {
  Facebook,
  Instagram,
  Logo,
  Twitter,
  LinkedIn,
  Youtube,
} from "../../images";
import { Link } from "react-router-dom";
// import { Facebook, Instagram, Twitter, LinkedIn, Youtube } from "../../images";
function Footer() {
  return (
    <footer>
      <div className={styles.footerWrapper}>
        <Link to="/">
          <div className={styles.logoWrapper}>
            <img src={Logo} alt="logo" />
          </div>
        </Link>
        <div className={styles.linksWrapper}>
          <div className={styles.linkStyles}>
            <Link to="/mission-vision">Mission & Vision</Link>
            <Link to="/who-we-are">Who we are?</Link>
            <Link to="/what-we-do">What we do?</Link>
          </div>
          <div className={styles.linkStyles}>
            <Link to="/our-projects">Our projects</Link>
            <Link to="/latest-news">Latest News - AI</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
          <div className={styles.linkStyles}>
            <Link to="/contact">Contact Us</Link>
            <Link to="/legal-mentions">Legal Mentions</Link>
          </div>
          <div className={styles.socialLinks}>
            <p>Follow Us on</p>
            <a
              href="https://www.facebook.com/Aizoh-110089837911891"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Facebook} alt="Facebook logo" />
            </a>
            <a href="https://www.instagram.com/ai_zoh/" target="_blank" rel="noreferrer">
              <img src={Instagram} alt="Instagram logo" />
            </a>
            <a href="https://twitter.com/_aizoh" target="_blank" rel="noreferrer">
              <img src={Twitter} alt="Instagram logo" />
            </a>
            <a
              href="https://www.linkedin.com/company/aizoh"
              target="_blank"
              rel="noreferrer"
            >
              <img src={LinkedIn} alt="LinkedIn logo" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCUe5B3GKT9eKDfYJocyTweQ"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Youtube} alt="Youtube logo" />
            </a>
          </div>
        </div>
      </div>
      <p className={styles.copyRight}>
        &copy; 2021 Aizoh | All right reserved
      </p>
    </footer>
  );
}

export default Footer;
