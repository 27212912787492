import React from "react";

import styles from "./styles.module.css";
import { WelcomeHeading } from "../../components";
import { MissionVision, WhyUs } from "../../images";

import WhoAreWe from "../who-we-are";
function AboutUs() {
  

  return (
    <div className={styles.aboutUs}>
      <div className={styles.contentWrapper}>
        <div className={styles.aboutUsHead}>
          <div className={styles.aboutUsHeadWrap}>
            <h3>About Us</h3>
          </div>
        </div>
        <WelcomeHeading title="Mission & Vision" />
        <div className={styles.mainHeading}>
          <p>
            Aizoh aims to develop AI embedded products and services which
            improves the world’s economies and societies.
          </p>
          <a
            href="https://www.youtube.com/channel/UCmjl8c8en8UcT1cP2hUhxIA"
            target="_blank"
            rel="noreferrer"
          >
            <img src={MissionVision} alt="" />
          </a>
        </div>
        <div className={styles.mainContent}>
          <p>
            At the academic level, we invest in fundamental research to deepen
            and strengthen our understanding of the state of art of AI and Big
            Data technologies.
          </p>
          <p>
            Our expertise and proficiency in AI, machine learning and deep
            learning model technologies enable us to provide advanced
            intelligent solutions to our customers in both industrial and
            academic world.
          </p>
          <p>
            We have presence in the following cities: Tokyo, Bangalore, Dubai
            and Paris.
          </p>
        </div>
        <WelcomeHeading title="Why Us" />
        <div className={styles.whyUs}>
          <p>Aizoh adopts “Insights into Action” strategy by</p>
          <img src={WhyUs} alt="" />
        </div>
        <div>{WhoAreWe()}</div>
        <div className={styles.empty}></div>
      </div>
    </div>
  );
}

export default AboutUs;
