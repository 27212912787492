import styles from "./styles.module.css";
import WelcomeHeading from "../../components/welcome-heading/index";
import { Nephrology } from "../../images";
function Newsroom({ history }) {
  return (
    <div className={styles.newsroomWrapper}>
      <WelcomeHeading title="Latest Updates of Events and many more" />
      {/* <h1>Join the workshop on Artificial Intelligence & Data Science</h1> */}
      <div
        className={styles.workshopWrapper}
        onClick={() => history.push("/workshop")}
      >
        <div className={styles.workshop}>
          <div className={styles.workshopContent}>
            <h1>
              Join our workshop on Artificial Intelligence and Big Data
            </h1>
            <div className={styles.workshopDate}>
              <p>September 9th, 2021</p>
              <p>100% Digital </p>
              <p>First Edition</p>
            </div>
          </div>
          {/* <div className={styles.organizer}>
            <div className={styles.logoWrap}>
              <img src={Logo} alt="logo" />
              <p>Organized by Aizoh</p>
            </div>
            <button onClick={() => history.push("/upcoming-events")}>
              Join
            </button>
          </div> */}
        </div>
      </div>
      {/* <h1>Participation in AI & Nephrology 2021 Conference</h1> */}

      <div
        className={styles.conference}
        onClick={() => history.push("/nephrology-conference")}
      >
        <div className={styles.conferenceWrapper}>
          <div className={styles.conferenceContent}>
          <h3>Participation in 2021 Conference</h3>

            <h1>
              Workshop on Artificial Intelligence & Nephrology 2021
            </h1>
            <div className={styles.conferenceDate}>
              <p>February 11-12,</p>
              <p>2021 100% Digital</p>
              <p>First Edition</p>
            </div>
          </div>
          <img src={Nephrology} alt="Nephrology" />
        </div>
      </div>
    </div>
  );
}

export default Newsroom;
