import React from "react";
import styles from "./styles.module.css";
import {
  DesktopTools,
  MobileTools,
  ProcessDesktop,
  ProcessMobile,
  WhatWeDoBanner,
} from "../../images";
import WelcomeHeading from "../../components/welcome-heading";
import CarouselComponent from "../../components/carousel";

function WhatWeDo() {
  return (
    <>
      {/* What we do Content  */}
      <div className={styles.mainWrapper}>
        <WelcomeHeading title="What We Do?" />
        <div className={styles.contentWrapper}>
          <img src={WhatWeDoBanner} alt="" />
        </div>

        <WelcomeHeading title="Machine Learning Tools" />
        <div className={styles.mobile}>
          <img src={ProcessMobile} alt="" />
        </div>
        <div className={styles.desktop}>
          <img src={ProcessDesktop} alt="" />
        </div>
        <div className={styles.toolsWrapper}>
          <img className={styles.mobileTools} src={MobileTools} alt="tools" />
          <img className={styles.desktopTools} src={DesktopTools} alt="tools" />
        </div>
        {/* Solutions Heading  */}
        <WelcomeHeading title="Solutions" />
        {/* Carousel  */}
        <CarouselComponent />
      </div>
    </>
  );
}

export default WhatWeDo;
