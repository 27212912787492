import {
  HealthcareOne,
  HealthcareTwo,
  BankingOne,
  BankingThree,
  BankingTwo,
  OtherSectorOne,
  OtherSectorFour,
  OtherSectorThree,
  OtherSectorTwo,
} from "../images";

export const solutions = [
  {
    domain: "healthcare",
    heading: "Solutions",
    description:
      "We are the solution providers for different sectors of industry including Healthcare sector, Banking sector, Energy sector etc. to name a few.",
    title: "Healthcare Data Analytics",
    cards: [
      {
        id: "hc01",
        image: HealthcareOne,
        info: "Preventive Care Dose of Artificial Intelligence",
        extraInfo: "AI models giving birth to new era in preventive health care.",
        challenge: "Patients who regularly intake prescribed drugs behave in predictable way thanks to common characteristics they share. One of the Healthcare provider requested for AI solution which will identify the patients on timely, which will facilitate Doctors to provide best treatment in proactively. There by reducing time and giving best possible medical treatment.",
        solution: "By using cognitive Artificial Intelligence by linking patients notes written by doctor within the clinic’s records, data like patients reports, diagnoses, pre and post diagnoses behavior. Machine learning models real time feedback to doctors about patient risk behavioral pattern and facilitating the doctors to take proactive actions.",
        results: "Better Patient Analysis, In creditable savings and Better patient doctor communication"
      },
      {
        id: "hc02",
        image: HealthcareTwo,
        info: "Data Science and AI improving excellent patient experience",
        extraInfo: "Providing an excellent patient experience goes far beyond how well caregivers treat physical ailments. It engages in an Entire patient journey from admission to discharge. <br/><br />By gathering patient Data received online (clinic website`) and arrival at Physical clinic. Aizoh can help health care providers to plan the better patient journey from admitting the patient to discharge.<br/><br />Utilizing the Data AI models can remind patients of their appointments without arranging calls to the patient.<br /><br />AI can make a common diagnosis based on the radiographs and thereby accelerate the plan in patient care. At discharge, the AI can predict the possible outcomes, especially adverse events, and provide a customized follow-up plan for the patient.<br /><br />Accurate past information of the patient diagnosed previously will guide in-house doctors, for decision making for the medical procedure during an emergency."
      },
      {
        id: "hc03",
        image: HealthcareOne,
        info:
          "Artificial intelligence Models in predicting patient flow",
        extraInfo: "Artificial intelligence can be used to forecasting patient flow and avoid unnecessary trips to the emergency department. Interpretation of clinical data would enable to segregate patients and predict outcomes in the emergency department operations. AI has direct influence on cost, efficient utilization of resources, and quality patient care. <br /><br />Intelligent patient flow management system: Artificial Intelligence is applied in patient flow management to predict patient flow, which will help health care provider to customize treatment plan, avoid unnecessary calls and visits.",
        solution: "Telemedicine / Virtual doctors for assistance."
      },
    ],
  },
  {
    domain: "banking",
    heading: "Solutions",
    description:
      "We are the solution providers for different sectors of industry including Healthcare sector, Banking sector, Energy sector etc. to name a few.",
    title: "Banking Data Analytics",
    cards: [
      {
        id: "b01",
        image: BankingOne,
        info: "AI Solution Detects Cheque Fraud",
        challenge: "Leading Financial Institution wanted to automate and streamline the Fraud Automation Process. In Signature verification of Transfer request forms, Signature on Cheques.",
        results: "Artificial intelligence-driven machine-learning solution to flag potential fraud by analyzing scanned images of handwritten cheques and Transfer application forms. Reduced manual effort while keeping initial and on-going costs low"
      },
      {
        id: "b02",
        image: BankingTwo,
        info: "Credit Card Collection",
        challenge: "The client required more sophisticated predictive technology to improve its debt collection process.",
        results: "Designed model to review voluminous data on slow- and no-paying credit customers. <br /><br />Identified factors that determine consumers’ payment behaviour and thus classified default stages of the customers. To focus more on most likely to default / will not pay."
      },
      {
        id: "b03",
        image: BankingThree,
        info: "Enhance Due Diligence in a short time.",
        challenge: "The client wanted to determine quickly and accurately research businesses to determine potentially illegal behavior, such as money laundering, insider trading / third party funds.",
        results: "Developed API to connect the company’s due diligence software to a machine-learning model any unusual pattern in transactions will trigger to verify and raise a query with the customer."
      },
    ],
  },
  {
    domain: "other",
    heading: "Solutions",
    description:
      "We are the solution providers for different sectors of industry including Healthcare sector, Banking sector, Energy sector etc. to name a few.",
    title: "Other sectors",
    cards: [
      {
        id: "o01",
        image: OtherSectorOne,
        info: "CHAT BOTS’ VS HUMANS",
        extraInfo: "The industrial revolution kick-started the ‘Man vs. Machine’. And with the technological revolution led by artificial intelligence (AI) and machine learning (ML), the battle has only deepened. Chabot’s, which are computer software that can mimic human conversations, rise to HUMAN VS MACHINE for handling all customer queries.",
        challenge: "Maximize FCR (First Contact Resolution) rate and reduce the AHT (Average Handling Time) of calls.",
        solution: "By studying incoming calls queries and most common problems/ complaints raised by customers, BOT was created within a built solution for all basic queries. By automating basic tasks where no human intervention is required. Chabot’s help balance the workload for human agents by handling low-priority queries that are high-volume in nature.<br /><br /> Chabot’s can be used to segment the first layer of customer queries so that they can be channeled to the right agent or team of agents who are trained."
      },
      {
        id: "o02",
        image: OtherSectorTwo,
        info: "Improved Decision-Making With Cloud Analytics",
        extraInfo: "",
        challenge: "A leading trader in Steel Billets, Steel Slabs, Pipes needed to create a new data architecture and management foundation—modernizing their technology platform to improve access to their materials, inventory, sales and financial data to manage efficiently their supply chain and implement robust analytics for predictive decision making.",
        solution: "Developing streamlined AI data model and cloud-based platform infrastructure. Client could implement AI tools to address prevailing challenges and enable new initiatives. The new cloud-based ecosystem improves query and self reporting – service capability, delivering key information to the business enabling rapid decision-making process. The new infrastructure provides transparency into inventory, sales and financial data."
      },
      {
        id: "o03",
        image: OtherSectorThree,
        info: "Data Analytics Solution Optimizes Equipment Utilization",
        extraInfo: "Lost Asset Utilization in Manufacturing units/ Mining’s is an very expensive asset, which effects them adversely in terms of productivity and financially.",
        challenge: "For one of the contractual Electronics Manufacturing unit Imbalance in cycle time between different work areas on any assembly line resulted in bottlenecks that idle some work areas and lead to lower utilization. Due this company incurred production lost minutes equate to thousands of placements, resulting in valuable product and revenue loss.",
        solution: "Advanced big data time Algorithms were used to segment data automatically into “work sessions”, the automated process gave our data scientist to identify the patterns in form linear equations. Which guided to fill gaps for Idle time in the manufacturing unit. With advanced AI systems providing both analysis and guidance, precious human hours can be refocused on creating immediate value."
      },
      {
        id: "o04",
        image: OtherSectorFour,
        info: "Unlocking Fast-Food Insights with Intelligence",
        extraInfo: "One of the major consequences of digital revolution is a fundamental change in how companies’ source, analyze, and ultimately use data.",
        challenge: "One of the local fast-food chain needed smart AI solutions for decision making on selecting menu, inventory management and more self-service data for business expansion through Franchise model.",
        solution: "Smart Dashboard was created using AI models which provided blitzkrieg sales information by consolidating data received from multiple outlets. <br /><br /> Smart Dashboard facilitated to resolve real time operational challenges particular the menu items, to prepare and deliver on timely manner."
      },
    ],
  },
];
