import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import "react-modal-video/css/modal-video.min.css";


import "./index.css";
import {
  HomePage,
  AboutUs,
  WhatWeDo,
  Contact,
  UpcomingEvents,
  Work,
  Solutions,
  Explore,
  Expertise,
  CoursesEvents,
  SmartProducts,
  Newsroom,
  Privacy,
  Nephrology,
  Mission,
  WhoAreWe,
  AIDataScience,
  LegalMentions,
  Venture
} from "./pages";
import { Header, Footer } from "./components";
import { NavigationContext } from "./contexts/navigation-context";
import { MenuContainer } from "./components";
import ScrollToTop from "./utils/scroll-to-top";
import Insights from "./pages/latest-news";

const Routes = () => {
  const [isNavigatorVisible, setNavigatorVisibility] = useState(false);
  return (
    <NavigationContext.Provider
      value={{ isNavigatorVisible, setNavigatorVisibility }}
    >
      <BrowserRouter>
        <MenuContainer />
        <ScrollToTop />
        <Header setNavigatorVisibility={setNavigatorVisibility} />
        <Switch>
          <Route path="/" component={HomePage} exact />
          <Route path="/about" component={AboutUs} />
          <Route path="/contact" component={Contact} />
          <Route path="/what-we-do" component={WhatWeDo} />
          <Route path="/workshop" component={UpcomingEvents} />
          <Route path="/our-projects" component={Work} />
          <Route path="/innovation-lab" component={Explore} />
          <Route path="/solutions/:domain" component={Solutions} />
          <Route path="/latest-news" component={Insights} />
          <Route path="/expertise-page" component={Expertise} />
          <Route path="/workshops-and-events" component={CoursesEvents} />
          <Route path="/smart-products" component={SmartProducts} />
          <Route path="/news&events" component={Newsroom} />
          <Route path="/privacy-policy" component={Privacy} />
          <Route path="/nephrology-conference" component={Nephrology} />
          <Route path="/mission-vision" component={Mission} />
          <Route path="/who-we-are" component={WhoAreWe} />
          <Route path="/AI-and-data-science" component={AIDataScience} />
          <Route path="/legal-mentions" component={LegalMentions} />
          <Route path="/venture/:vid" component={Venture} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </NavigationContext.Provider>
  );
};

ReactDOM.render(<Routes />, document.getElementById("root"));
// ReactDOM.render(<h2>coming soon...</h2>, document.getElementById("root"));
