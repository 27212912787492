import styles from "./styles.module.css";
import hospital from "../../images/workPage/image_16.png";
import { Blue, DefaultButton, WelcomeHeading } from "../../components/index";

import { White } from "../../components/index";
import watch from "../../images/workPage/n1zrvlxf_im.jpg";
import xRay from "../../images/workPage/n1zrvlxf_im_1_.jpg";
import twitter from "../../images/workPage/Group-9.png";
import target from "../../images/workPage/n1zrvlxf_im_3_.png";
import crm from "../../images/workPage/tableau_1.jpg";
import { Link } from "react-router-dom";

function Work() {
  return (
    <div className={styles.work}>
      <WelcomeHeading title="Artificial Intelligence in Healthcare Industry: The Haemodialysis Patients with Cramps Initiative" />
      <div className={styles.artiIntel}>
        {/* <h3>
          Artificial Intelligence in Healthcare Industry: The Haemodialysis
          Patients with Cramps Initiative
        </h3> */}
        <p>
          <span>Project Reference - </span>
          INSERM U1016, Institute Cochin, Hospital Cochin, University of Paris.{" "}
          <br />
        </p>
        <div className={styles.artiIntelCenter}>
          <div className={styles.artiIntelFlex}>
            <div className={styles.artiIntelImage}>
              <img src={hospital} alt="bed" />
            </div>
            <p>
              <span>Objective – </span> Study of Microbiota quality,
              mitochondrial activity and the occurrence of muscle cramps in
              Haemodialysis patients.
              <br />
              <span>Patients with cramps – </span> Data provides different
              patterns <br />
              <span>Patterns without Cramps- </span> Data provides different
              Patterns.
            </p>
          </div>
        </div>
        <p>
          By creating Algorithms and Machine Learning Models, we will be able to
          get insight on who will get cramps and accordingly prepare the
          clinical procedures. Their by reducing time ,process and reduce
          patients stress.
        </p>
        <div className={styles.projects}>
          <div className={styles.defaultBtn}>
            <Link to="/contact">
              <DefaultButton btnName="More Info" />
            </Link>
          </div>
        </div>
        <p>
          <span>
            Aizoh participated in an International event held in Paris
            2021. Artificial Intelligence and Nephrology.
          </span>
        </p>
        <div className={styles.linkStyles}>
          <p>
            <a href="https://targeting-ai.com/agenda" target="_blank" rel="noreferrer">
              https://targeting-ai.com/agenda
            </a>
          </p>
          <p>
            <a href="https://youtu.be/NjV_4_VOSTg" target="_blank" rel="noreferrer">
              Click here to watch
            </a>
          </p>
        </div>
      </div>
      <Blue
        style={{ color: "#fff" }}
        heading="Processing data from wearable device to predict the disease."
        description="The data collected from the sensors in electronic wearable devices like smartwatches, Fitbits etc.. used to predict the probable disease using machine learning algorithms. The ML model will alert the individuals to take initial precautionary measures."
        img={watch}
      />
      <White
        title="Development of deep learning model to predict  covid from x ray images"
        information="Xray images of the covid and non-cvovid patients were collected and processed using a convolutional neural network model to classify future x-ray of the patient to predict whether he is effected with covid."
        image={xRay}
      />
      <Blue
        style={{ color: "#fff" }}
        heading="Natural language processing and sentimental analysis  of twitter trends"
        description="The data collected from the sensors in electronic wearable devices like smartwatches, FIT Bits etc.. used to predict the probable disease using machine learning algorithms. The ML model will alert the individuals to take initial   precautionary measures.
"
        img={twitter}
      />
      <White
        title="Prediction of targets achieved by salesmen using historical data."
        information="The Retail store chain wanted machine learning model to incentivize their salesperson to boost their morale. Our team built the ML model using the historical data which can forecast the targets of the salesperson and thus getting bonuses proactively. 
"
        image={target}
      />
      <Blue
        style={{ color: "#fff" }}
        heading="Streamlining data from databases into Tableau and Power BI dashboard."
        description="
Prepared Dashboard using client’s database to visualize their data in real time and giving them an opportunity to take quick business decisions.
"
        img={crm}
      />
    </div>
  );
}

export default Work;
