import styles from "./styles.module.css";

function White({ title, information, image }) {
  return (
    <div className={styles.white}>
      <h3>{title}</h3>
      <div className={styles.startWrapWhite}>
        <div className={styles.flexWrapWhite}>
          <div className={styles.xRay}>
            <img src={image} alt="watch" />
          </div>
          <div className={styles.textImageWhite}>
            <p>{information}</p>
            {/* <div className={styles.btn}>
              <DefaultButton
                btnName="Discover"
                style={{ border: "2px solid #235087" }}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default White;
