import Modal from "react-modal";
import { CloseBtn } from "../../images";

const customStyles = {
  content: {
    // top: "50%",
    // left: "50%",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
    background: "rgba(35, 80, 135, 0.8)",
    height: "75vh",
    width: "80vw",
    margin: "auto",
  },
};
// Modal.setAppElement("#yourAppElement");

function SolutionsModal({ isModalVisible, setModalVisibility, children }) {
  return (
    <Modal isOpen={isModalVisible} style={customStyles}>
      <img
        src={CloseBtn}
        alt="close-icon"
        onClick={() => setModalVisibility(!isModalVisible)}
      />
      {children}
    </Modal>
  );
}

export default SolutionsModal;
