import styles from "./styles.module.css";


function ImageLeft ({heading, description, img}) {
    return (
        <div>
            <div className={styles.ideasContainer}>
            <div className={styles.ideaLeftContainer}>
                <div className={styles.leftWrapper}>
                <div className={styles.ideaImage}>
                   <img src={img} alt="loading..." />
                  </div> 
                 <div className={styles.leftInfo}>
                   <h4>{heading}</h4>
                   <p>{description}</p>
                   </div>  
                  </div>         
             </div>   
                </div>
        </div>
    )
}
export default ImageLeft ;