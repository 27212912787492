import { WelcomeHeading } from "../../components";
import UpcomingPeople from "../../components/upcomingEvents";
import { Godric, Helga, Pattern, Salazar, Galih } from "../../images";
import Brochure from "../../images/upcomingEvents/AI Workshop - Agenda.pdf";
import styles from "./styles.module.css";
function UpcomingEvents() {
  return (
    <div className={styles.upcomingPage}>
      <div className={styles.workshopWrapper}>
        <div className={styles.workshop}>
          <div className={styles.workshopContent}>
            <h1>Workshop on Artificial Intelligence and Big Data</h1>
            <div className={styles.workshopDate}>
              <p>September 9th, 2021</p>
              <p>100% Digital </p>
              <p>First Edition</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.mainContent}>
        <WelcomeHeading title="Workshop on Artificial Intelligence and Big Data" />
        <h3>Organized By: Aizoh</h3>
        <p>
          Following the success of our Artificial Intelligence Conference held
          in Paris in February 2021 (www.targeting-ai.com), we are pleased to
          announce our introductory workshop dedicated to Big Data and AI which
          will take place on 9th September, 2021. This interactive workshop will be
          streamed live to allow access to participants across the globe. You
          can stream and download the workshop sessions via our online platform
          if you missed it.
        </p>
        <p>
          The aim of this workshop is to introduce the fascinating world of AI
          to all the professionals irrespective of their background, and to
          explore its latest advances in all fields. During the workshop, Big
          Data experts and AI practitioners will demonstrate experiential and
          actionable insights with proven use of cases across different
          industries
        </p>
        <h3>Among the topics which will be addressed:</h3>
        <ul>
          <li>
            Introduction to Artificial Intelligence: Past, Present and Future{" "}
          </li>
          <li>
            Data and Big Data solutions for Success of Business Strategies
          </li>
          <li>
            Use of Algorithms, Machine Learning, Deep learning, and AI Models to
            positively impact your business and maximize profits
          </li>
          <li>
            Presentation of the latest Trends, the opportunities and challenges
            ahead
          </li>
          <li>
            Success stories of AI and Machine Learning Specialists and their
            real implementations
          </li>
        </ul>
        <p>
          This workshop is intended for anyone from any field who wishes to kick
          start their journey to the world of AI from initiation to improvement
        </p>
        <p>
          We are looking forward to meeting you very soon. Please do not
          hesitate to contact us for any further information or inquiry.
        </p>

        <div className={styles.timing}>
        <a href="https://www.xing-events.com/AI_Workshop.html" target="_blank" rel="noreferrer">
            <p
              style={{
                width: "fit-content",
                paddingBottom: "5px",
                cursor: "pointer",
                color: "#11557c",
                fontWeight: "500",
              }}
            >
              Click here to register for the event
            </p>
          </a>
          <a href={Brochure} download>
            <p
              style={{
                width: "fit-content",
                paddingBottom: "5px",
                cursor: "pointer",
                color: "#11557c",
                fontWeight: "500",
              }}
            >
              Click here to download the brochure for the event
            </p>
          </a>
          <img src={Pattern} alt="dots" />
        </div>
        <h3>About the Speaker</h3>
        <div className={styles.profileWrapper}>
          <UpcomingPeople
            profileImg={Helga}
            occ={"Associate-Professor in Computer Science"}
            locatedAt={"GBSB Global Business School, Spain"}
            profName={"Hicham Khoury"}
            fbLink={"#"}
            linkedInLink={"#"}
            twiiterLink={"#"}
          />
          <UpcomingPeople
            profileImg={Godric}
            occ={"PhD researcher in Computer Science"}
            locatedAt={"Femto-ST Institute, University of Bourgogne–Franche-Comte, France"}
            profName={"Selene Leya Cerna"}
            fbLink={"#"}
            linkedInLink={"#"}
            twiiterLink={"#"}
          />
          <UpcomingPeople
            profileImg={Salazar}
            occ={"Professor in Computer Science"}
            locatedAt={"Femto-ST Institute, University of Bourgogne–Franche-Comte, France"}
            profName={"Christophe Guyeux"}
            fbLink={"#"}
            linkedInLink={"#"}
            twiiterLink={"#"}
          />
          <UpcomingPeople
            profileImg={Galih}
            occ={"Director of Data Science XQ Informatics"}
            locatedAt={"Indonesia"}
            profName={"Galih Permadi"}
            fbLink={"#"}
            linkedInLink={"#"}
            twiiterLink={"#"}
          />
        </div>
        <div className={styles.timing}>
          <p>Date & Time:</p>
          <p>9th September 2021, 12:50 EST</p>
          <p>For any further queries, email us at- info@aizoh.com</p>
          <img src={Pattern} alt="dots" />
        </div>
      </div>
    </div>
  );
}

export default UpcomingEvents;
