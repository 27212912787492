import React from "react";
import { WelcomeHeading } from "../../components";
import styles from "./styles.module.css";
function LegalMentions() {
  return (
    <div className={styles.mainWrapper}>
      <WelcomeHeading title="Legal Mentions" />
      <div className={styles.contentWrapper}>
        <p>
          This legal mention applies to this website and domains. It does not
          apply to websites and domains for Aizoh companies which, for example,
          have amended legal information due to national legislation. Therefore,
          please note the legal mentions of all Aizoh websites you visit or
          programs you use.
        </p>
        <br />
        <p>
          In some cases, Aizoh websites also contain links to the websites of
          third party, Non- Aizoh companies, to which these legal mentions does
          not apply.
        </p>
        <br />
        <p>
          Information published under the Aizoh domains may contain references
          to products and services which have not been advertised or made
          available in your country. Such information does not imply that Aizoh
          intends to offer these products or services in your country at a later
          date. If necessary, you may contact our organization which will be
          pleased to provide you with information on the availability of these
          products and services. The presentation of the products and services
          on our website does not constitute a binding sales offer.
        </p>{" "}
        <br />
        <p>
          If software is offered for download free of charge, Aizoh assumes no
          liability for any damages resulting from the download or the use of
          the software. The download and use of the software is carried out
          exclusively at the user's own risk and without any liability or
          warranty, except in the event of any intent or gross negligence on the
          part of Aizoh.
        </p>{" "}
        <br />
        <p>
          In several places, we mention and provide links to third party
          websites. We do this only if we are completely convinced of the
          seriousness of the provider concerned. However, Aizoh is not
          responsible for the data protection stipulations or contents of these
          websites and assumes no liability in this regard. These external links
          are symbolized by source file.
        </p>{" "}
        <br />
        <p>
          Aizoh holds and reserves all rights to the content and layout of its
          websites. The intellectual property contained in our websites and our
          brands are protected. The text, images and graphics on our websites
          and their layout etc, as well as animations and software are subject
          to copyright law and other protective legislation. Reproduction,
          rendition or playback of the whole or parts thereof is prohibited
          unless our written permission has been obtained in advance. This
          website does not grant any license for the use of the intellectual
          property. Aizoh reserves the right to change, suspend or close down
          websites at any time and without prior notice.
        </p>{" "}
        <br />
        <p>
          Despite the careful compilation of the content of our website, we
          assume no liability for its correctness, accuracy, completeness or for
          undisrupted access to it. Insofar as is legally permissible, we assume
          no liability for direct or indirect damages including loss of earnings
          arising from the use of our website, or in connection with information
          made available on the website. We reserve the right to change or
          supplement the information provided, and to do so at any time.
        </p>
      </div>
    </div>
  );
}

export default LegalMentions;
