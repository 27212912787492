import { SectionsMenu } from "../../components"

import styles from "./styles.module.css";
import Logos from "../../components/experience";
import one from "../../images/exploring-ants/one.svg";
import two from "../../images/exploring-ants/two.svg";
import three from "../../images/exploring-ants/three.svg";
import four from "../../images/exploring-ants/four.svg";
import five from "../../images/exploring-ants/five.svg";
import six from "../../images/exploring-ants/six.svg";
import mobile from "../../images/exploring-ants/ideas_mobile.jpg";
import desktop from "../../images/exploring-ants/ideas_desktop.jpg";
import polygon from "../../images/exploring-ants/Polygon_Shapes.svg";

function Explore() {
  return (
    <div className={styles.explore}>
      <div className={styles.ideas}>
        <div className={styles.wallpaper}>
          <div>
            <h3>
              We Transform Smart <br />
              Innovative Ideas into Actions
            </h3>
          </div>
        </div>
        <SectionsMenu />
        <div className={styles.ideasContainer}>
          <h3>Give Life to your Ideas</h3>
          <div className={styles.polygonLeft}>
            <img src={polygon} alt="" />
          </div>
          <div className={styles.containerFlex}>
            <div className={styles.mobile}>
              <img src={mobile} alt="loading.." />
            </div>
            <div className={styles.desktop}>
              <img src={desktop} alt="loading.." />
            </div>
          </div>
          <div className={styles.polygonRight}>
            <img src={polygon} alt="" />
          </div>
        </div>
        <div className={styles.logoContainer}>
          <div className={styles.logoHead}>
            <h3>What’s In Experience</h3>
          </div>
          <div className={styles.logos}>
            <div>
              <Logos image={one} title="Simplified Complex Value Stories" />
            </div>
            <div>
              <Logos image={two} title="Resources" />
            </div>
            <div>
              <Logos image={three} title="Editable Content" />
            </div>
          </div>
          <div className={styles.logos}>
            <div>
              <Logos image={four} title="Increase Sales Opportunities" />
            </div>
            <div>
              <Logos image={five} title="Real Time Immersion" />
            </div>
            <div>
              <Logos image={six} title="User Analytics" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Explore;
