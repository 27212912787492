import React from "react";
import { WelcomeHeading } from "../../components";
import {
  RingDesktop,
  RingMobile,
  Ceo,
  DataScientist,
  Mananger,
} from "../../images";
import styles from "./styles.module.css";
function WhoAreWe() {
  return (
    <div className={styles.mainWrapper}>
      <WelcomeHeading title="Who We Are?" />
      <div className={styles.contentWrapper}>
        <p>
          We are a global team of talented individuals with over
          <span> 15 years of industrial experience.</span> We have become a
          valuable and competitive <span>provider</span> in
          <span> global AI algorithms</span>, and one of the most
          <span> prolific contributors in AI and Big Data technologies.</span>
        </p>
      </div>
      <div className={styles.mobile}>
        <img src={RingMobile} alt="who are we" />
      </div>
      <div className={styles.desktop}>
        <img src={RingDesktop} alt="who are we" />
      </div>
      <div className={styles.contentWrapper}>
        <WelcomeHeading title="Our Crew" />
        <div className={styles.crewContent}>
          <h3>The people making it all happen.</h3>
          <p>
            Our Crew comprises a group of marketing specialists, business
            consultants across the industries, data scientists, and Big Data
            engineers. Our crew has a rich experience in analytical strategies,
            machine learning, AI, Sentiment Analysis, data forecasting, semantic
            Analysis, network Analysis, NLP tools, and speech and natural
            processing Language.
          </p>
          <div className={styles.crewImages}>
          <div className={styles.sides}>
              <div className={styles.crewContain}>
                <img src={DataScientist} alt="Data Scientist" />
                <p className={styles.crewName}>Zheng lui</p>
                <p className={styles.crewDesig}>Data Scientist</p>
              </div>
            </div>
            <div className={styles.centre}>
              <div className={styles.crewContain}>
                <img src={Ceo} alt="ceo" />
                <p className={styles.crewName}>
                  Abdul
                  <br /> Samad Rahim
                </p>
                <p className={styles.crewDesig}>CEO & Founder</p>
              </div>
            </div>
            
            <div className={styles.sides}>
              <div className={styles.crewContain}>
                <img src={Mananger} alt="Mananger" />
                <p className={styles.crewName}>Maria Ludwig</p>
                <p className={styles.crewDesig}>Digital knowledge Manager</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhoAreWe;
