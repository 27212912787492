import { useState } from "react";

import styles from "./styles.module.css";
import { ContactInfo, WelcomeHeading } from "../../components";

function Contact() {
  const [formDetails, setFormDetails] = useState({
    name: "",
    contact: "",
    email: "",
    organisation: "",
    industry: "",
    country: "",
    problem: "",
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ message: "", error: false });
  const [tncChecked, setTncChecked] = useState(false);

  const [email, setEmail] = useState("");
  const [emailMessage, setEmailMessage] = useState({message: "", error: "false"});
  const [emailLoading, setEmailLoading] = useState(false);

  const onChange = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
  };

  const subscribe = async () => {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
   if(!emailPattern.test(email)){
      setEmailMessage({message: "Please enter a valid email", error: true});
      return;
   }
   let encodedEmail = encodeURI(email);

    try {
      setEmailLoading(true);
      const subscribe = await fetch(
        `/.netlify/functions/subscribe?email=${encodedEmail}`
      );
      if (subscribe.status === 200) {
        setEmailLoading(false);
        setEmailMessage({ message: "Thanks for subscribing, Stay tuned..", error: false });
        setEmail("")
      }

      if (subscribe.status > 500) {
        throw new Error("something went wrong!");
      }
    } catch (e) {
      setEmailLoading(false);
      setEmailMessage({
        message: "Something went wrong! Please try again later.",
        error: true,
      });
    }

  }

  const onSubmit = async (e) => {
    e.preventDefault();
    let formMessage = "";
    for (let key of Object.keys(formDetails)) {
      if (formDetails[key]) {
        formMessage = `
          ${formMessage}
          <p>${key}: ${formDetails[key]}</p>
        `;
      }
    }

    formMessage = encodeURI(formMessage);

    try {
      setLoading(true);
      const sendEmail = await fetch(
        `/.netlify/functions/sendEmail?formDetails=${formMessage}`
      );
      if (sendEmail.status === 200) {
        setLoading(false);
        setMessage({ message: "Thanks for writing to us. A member of our team will be in touch soon.", error: false });
        setFormDetails({
          name: "",
          contact: "",
          email: "",
          organisation: "",
          industry: "",
          country: "",
          problem: "",
        });
        setTncChecked(false);
      }

      if (sendEmail.status > 500) {
        throw new Error("something went wrong!");
      }
    } catch (e) {
      setLoading(false);
      setMessage({
        message: "Something went wrong! Please try again later.",
        error: true,
      });
    }
  };

  return (
    <div className={styles.contactContainer}>
      <WelcomeHeading
        style={{ paddingBottom: "30px" }}
        title="How can we help?"
        // Paragraph="Have a project or an idea you'd like to collaborate on? Interested in
        //   what Data Design can do for you?"
      />
      <div>
        <ContactInfo />
      </div>
      <div className={styles.thirdInfo}>
        <div className={styles.textContainer}>
          <h3>Contact Us</h3>
        </div>
        {/* <img src={pattern1} alt="" /> */}
        <h4
          style={{ color: message.error ? "red" : "#6007B1", marginTop: "10px" }}
        >
          {message.message}
        </h4>
      </div>

      <div className={styles.forthInfo}>
        <form>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formDetails["name"]}
            onChange={onChange}
          />
          <input
            type="text"
            name="contact"
            placeholder="Contact Number"
            onChange={onChange}
            value={formDetails["contact"]}
          />
          <input
            type="text"
            name="email"
            placeholder="Email Address"
            onChange={onChange}
            value={formDetails["email"]}
          />
          <input
            type="text"
            name="organisation"
            placeholder="Organisation Name"
            onChange={onChange}
            value={formDetails["organisation"]}
          />
          <input
            type="text"
            name="industry"
            placeholder="Industry"
            onChange={onChange}
            value={formDetails["industry"]}
          />
          <input
            type="text"
            name="country"
            placeholder="Country"
            onChange={onChange}
            value={formDetails["country"]}
          />
          <textarea
            type="text"
            name="problem"
            placeholder="Message"
            onChange={onChange}
            value={formDetails["problem"]}
          />
          <div className={styles.bySubmiting}>
            <input
              type="checkbox"
              checked={tncChecked}
              onChange={(e) => setTncChecked(e.target.checked)}
            />
            <p>
              By submitting this form, I agree to receive communications from Aizoh with my enquiry. My information will be
              processed in accordance with Aizoh Privacy Policy.
            </p>
          </div>
          <div>
            <button onClick={onSubmit} disabled={!tncChecked}>
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
          {/* <img src={pattern1} alt="" /> */}
        </form>
      </div>
      <div className={styles.newsLetterWrapper}>
        <div className={styles.newsLetter}>
          <h3>Sign up to our newsletter</h3>
          <div>
          <input placeholder="Email Address" value={email} type="email" onChange={e => {setEmail(e.target.value); setEmailMessage({message: "", error: false})}}/>
              <button onClick={subscribe} >{emailLoading ? "Sending..." : "Send"}</button> <br />
          </div>
              <p style={{color: emailMessage.error ? "red" : "white", fontSize: "16px", textAlign: "center" }}>{emailMessage.message}</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
