import React from "react";
import "./aianddata.css";
import styles from "../about-us/styles.module.css";
import {
  DataAnalyticsImage,
  AnalysisImage,
  AiImage,
  DataCollectionImage,
  AiData2,
  AiData3,
  AiData4,
  WhoAreWe,
} from "../../images";
import image1 from "../../images/aboutUs/who-are-we.jpg";
import image2 from "../../images/aboutUs/tab-desktop.jpg";
import WelcomeHeading from "../../components/welcome-heading";
// import EmailTextArea from "../../components/email-textarea";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function AiAndData() {
  const AiAndDataScience = [
    {
      src: AiData3,
      Heading: "Big Data Analytics",
      SubHeading1: "Connect the dots",
      SubHeading2: "",
      contentParagraph:
        "Insights often live at the boundaries. By connecting data can reveal new insights, and this insight information from Data is very critical for Organizations success.",
      ImageParagraph1:
        "Our Data Scientists can bring you the right data analytics solutions to your organization. With innovative approaches and leveraging data visualization tools and cloud infrastructure, we can help you extract the best possible insights from your data. which you didn’t even know. Our services set ranges from consulting to implementing analytics solution for your company",
      ImageParagraph2: " ",
    },
    {
      src: AiData2,
      Heading: "Business Intelligence",
      SubHeading1: "Think before Ink",
      SubHeading2: "",
      contentParagraph: "Intelligence needs a purpose and plan.",
      ImageParagraph1:
        "Business intelligence services from AI and Data Science is process oriented where our Business intelligence team continually test, evaluate new information retrieved from the data more accurately will Transform data into rich visuals and interactive dashboards. We cash Leverage on the power of data and robust Business Intelligence tools such as Power BI, Tableau and other open source tools with advanced technology. ",
    },

    {
      src: AiData4,
      Heading: "AI & ML Applications",
      SubHeading1: "Leap into the future",
      SubHeading2: "",
      contentParagraph: "Human - to - Machine Interaction",
      ImageParagraph1:
        "With AI, you can ask a machine questions – out loud – and get answers about sales, inventory, customer retention, fraud detection, predicting churn rate in an eCommerce company, predicting medical diagnosis to predicting consumer retention. Our team capable of developing AI and ML applications models across the industry. ",
      ImageParagraph2:
        "Which assist business in quick decision making, reducing operating cost and more profits. ",
    },
    {
      src: WhoAreWe,
      Heading: "About Us",
      SubHeading1: "Who We Are",
    },
  ];

  const resetScroll = () => {};

  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          maxWidth: "1400px",
          margin: "0 auto",
        }}
      >
        {/* Welcome Heading and text paragraph */}
        <WelcomeHeading
          title=" We change the way people view, access and use data to make better decisions."
          Paragraph="AI and Data Science aims at solving business problems utilizing the power of cognitive AI and cloud infrastructure. We at AI and Data Science help to clean and explore the unstructured data to bring forth the insights in it. These insights will help you make data-driven strategies that will give you an edge over your competitors."
        />
        <div className="tabsWrapper">
          <Tabs defaultIndex={0}>
            <TabList>
              <Tab onClick={resetScroll}>Process</Tab>
              {/* <Tab onClick={resetScroll}>Data Analytics</Tab> */}
              <Tab onClick={resetScroll}>Big Data Analytics</Tab>
              <Tab onClick={resetScroll}>Business Intelligence </Tab>
              <Tab onClick={resetScroll}>AI & ML Applications</Tab>
            </TabList>
            <TabPanel>
              <WelcomeHeading title="What we do!" />

              <div className="whatwedoContentWrapper">
                <div className="whatwedoContent">
                  <div className="wwdImageWrap">
                    <img src={DataCollectionImage} alt="wwd" />
                  </div>
                  <div className="wwdtextWrap">
                    <p>
                      Data collection from different sources like the internet
                      and company databases
                    </p>
                  </div>
                </div>
                <div className="whatwedoContentReverse">
                  <div className="wwdImageWrapReverse">
                    <img src={DataAnalyticsImage} alt="wwd" />
                  </div>
                  <div className="wwdtextWrapReverse">
                    <p>
                      Unify different formats of information into a single data
                      set and explore hidden key interests and patterns.
                    </p>
                  </div>
                </div>
                <div className="whatwedoContent">
                  <div className="wwdImageWrap">
                    <img src={AnalysisImage} alt="wwd" />
                  </div>
                  <div className="wwdtextWrap">
                    <p>
                      Visualizing the data to derive insights and preparing for
                      machine learning model
                    </p>
                  </div>
                </div>
                <div className="whatwedoContentReverse">
                  <div className="wwdImageWrapReverse">
                    <img src={AiImage} alt="wwd" />
                  </div>
                  <div className="wwdtextWrapReverse">
                    <p>
                      Developing statistical machine learning models to be used
                      for dat forecasting and prediction using vibrant computer
                      languages and statistical concepts
                    </p>
                  </div>
                </div>
              </div>
              <WelcomeHeading title="Who are we?" />
              <div className={styles.whoAreWe}>
                <div className={styles.mobile}>
                  <img src={image1} alt="who are we" />
                </div>
                <div className={styles.desktop}>
                  <img src={image2} alt="who are we" />
                </div>
              </div>
            </TabPanel>
            {AiAndDataScience.map((item, index) => (
              <TabPanel key={index}>
                <div className="aiAndDataWrapper">
                  {/* content with image  */}
                  <div className="aiContentWrapper">
                    <h1 className="aiTopicHeading">{item.Heading}</h1>
                    <div className="aiSubHeadingWrap">
                      <h3>{item.SubHeading1}</h3>
                      <h3>{item.SubHeading2}</h3>
                    </div>
                    <div className="aiContentParagraph">
                      <h3>{item.contentParagraph}</h3>
                    </div>

                    <div className="aiImageParagraphWrapper">
                      <div className="aiImageWrap">
                        <img src={item.src} alt="" />
                      </div>
                      <div className="aiImageParagraph">
                        <p>{item.ImageParagraph1}</p>
                        <p>{item.ImageParagraph2}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default AiAndData;
