import { AIEyeFlow, AIEyeHero, XplorBeeHero, XploreBeeFlow, RecapteFlow, RecapteHero } from "../images"
import { AiEyeDoc, RecapteDoc, XplorBeeDoc } from "../documents/ventures"

const ventures = [
    {
        id: "ai-eye",
        heroImage: AIEyeHero,
        heroAltText: "ai eye hero image",
        workflowImage: AIEyeFlow,
        workflowAltText: "ai eye work flow",
        headline: "",
        description: "Ai Eye, powered by Aizoh is an advanced recognition software that includes people count, facial scan, images and vehicle number plate recognition. Our software use Machine Learning and advanced computer vision technology platforms.",
        downloadLink: AiEyeDoc,
        downloadFileName: "Ai Eye - Powered by Aizoh.pdf"
    },
    {
        id: "xplorbee",
        heroImage: XplorBeeHero,
        heroAltText: "xplor bee hero image",
        workflowImage: XploreBeeFlow,
        workflowAltText: "Xplor Bee work flow",
        headline: "Track, Monitor and manage key words",
        description: "Xplor Bee powered by Aizoh is a Software-as-a Service that helps the users find information on keywords-basis in Social Media (Twitter, Instagram, Facebook) and in Online Media (News Sites like BBC, CNN and region specific channels, etc).",
        downloadLink: XplorBeeDoc,
        downloadFileName: "Explore Bee-Powered by Aizoh.pdf"
    },
    {
        id: "recapte",
        heroImage: RecapteHero,
        heroAltText: "recapte hero image",
        workflowImage: RecapteFlow,
        workflowAltText: "recapte workflow",
        headline: "Deep Learning-powered Document Extraction as a Service",
        description: "Recapte uses AI and deep learning technology to capture and automate information from your documents (e.g. PDFs). Combined with our custom model services to handle your specific documents with high accuracy to extract text and tables from your documents and forms",
        downloadLink: RecapteDoc,
        downloadFileName: "Recapte-Powered by Aizoh.pdf"
    },
]

export default ventures;
