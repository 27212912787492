import styles from "./styles.module.css";

function Blue({ heading, description, img, style }) {
  return (
    <div className={styles.blue}>
      <h3 style={style}>{heading}</h3>
      <div className={styles.startWrap}>
        <div className={styles.flexWrap}>
          <div className={styles.watch}>
            <img src={img} alt="watch" />
          </div>
          <div className={styles.textImage}>
            <p>{description}</p>
            {/* <div className={styles.blueBtn}>
              <DefaultButton
                btnName="Discover"
                style={{ border: "2px solid #fff" }}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blue;
