import { YellowArrow } from "../../images";
import styles from "./index.module.css";
export default function SolutionCards({
  cardImg,
  cardInfo,
  onClick,
  postDate,
}) {
  return (
    <div className={styles.cards}>
      <div className={styles.card} onClick={onClick}>
        <img src={cardImg} alt="" />
        <div className={styles.cardContent}>
          <h4>{postDate}</h4>
          <p>{cardInfo}</p>
          <div>
            <img src={YellowArrow} alt="arrow" onClick={onClick} />
          </div>
        </div>
      </div>
    </div>
  );
}
