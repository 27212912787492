import { Link, useLocation, useParams } from "react-router-dom";

import solutionStyles from "../../pages/solutions/styles.module.css";

const SectionsMenu = ({ venture="" }) => {
  const location = useLocation();
  const getPath = () => location.pathname.substring(1);
  const params = useParams();

  if (venture) {
    console.log("--->", params);
    return (
      <div className={solutionStyles.solutionsTilesWrapper}>
        <Link to="/venture/ai-eye">
          <div
            className={
              venture === "ai-eye"
                ? `${solutionStyles.solutionTile} ${solutionStyles.activeSolutionTile}`
                : solutionStyles.solutionTile
            }
          >
            Ai Eye
          </div>
        </Link>
        <Link to="/venture/xplorbee">
          <div
            className={
              venture === "xplorbee"
                ? `${solutionStyles.solutionTile} ${solutionStyles.activeSolutionTile}`
                : solutionStyles.solutionTile
            }
          >
            Xplor Bee
          </div>
        </Link>
        <Link to="/venture/recapte">
          <div
            className={
              venture === "recapte"
                ? `${solutionStyles.solutionTile} ${solutionStyles.activeSolutionTile}`
                : solutionStyles.solutionTile
            }
          >
            Recapte
          </div>
        </Link>
      </div>
    );
  }

  return (
    <div className={solutionStyles.solutionsTilesWrapper}>
      <Link to="/AI-and-data-science">
        <div
          className={
            getPath() === "AI-and-data-science"
              ? `${solutionStyles.solutionTile} ${solutionStyles.activeSolutionTile}`
              : solutionStyles.solutionTile
          }
        >
          AI & Data Science
        </div>
      </Link>
      <Link to="/innovation-lab">
        <div
          className={
            getPath() === "innovation-lab"
              ? `${solutionStyles.solutionTile} ${solutionStyles.activeSolutionTile}`
              : solutionStyles.solutionTile
          }
        >
          Innovation lab
        </div>
      </Link>
      <Link to="/smart-products">
        <div
          className={
            getPath() === "smart-products"
              ? `${solutionStyles.solutionTile} ${solutionStyles.activeSolutionTile}`
              : solutionStyles.solutionTile
          }
        >
          Smart Products
        </div>
      </Link>
      <Link to="/workshops-and-events">
        <div
          className={
            getPath() === "workshops-and-events"
              ? `${solutionStyles.solutionTile} ${solutionStyles.activeSolutionTile}`
              : solutionStyles.solutionTile
          }
        >
          Workshops & Events
        </div>
      </Link>
    </div>
  );
};

export default SectionsMenu;
