import styles from "./styles.module.css" ;
import MobileTools from "../../images/what-we-do/what-we-do-mobile-tools.svg";
import DesktopTools from "../../images/what-we-do/what-we-do-desktop-tools.svg";
import pattern from "../../images/exploring-ants/Polygon_Shapes.svg" ;
import CarouselComponent from "../../components/carousel";

function Expertise () {
    return(
        <div className={styles.expertise}>
            <div className={styles.banner}>
            <p>Expertise that defines <br/>Businees Engineering</p>
            </div>
            <div className={styles.description}>
                 <div className={styles.leftTop}>
                    <img src={pattern} alt="pattern" />
                </div>
                <p>We constantly expand the scope of our skills by developing, Enhancing and adapting to the most advanced technological platforms. We pride in ourselves to have effective, relevant long term perspective in tools such as Python, Spider, Mongo, and TABLEAU. <br/> <br/> 
                We use cloud platforms such as AWS, Azure, Google cloud, to have highest level of possible security. <br/><br/> 
​                We up skill our clients with the expertise that helps them to be more сompetitive in the market            and gain more profit eventually. </p>
                <div className={styles.rightBottom}>
                  <img src={pattern} alt="pattern" />
                 </div>
            </div>
            <div className={styles.tools}>
                <h3>Machine Learning Tools</h3>
                <div className={styles.mobileTools}>
                  <img  src={MobileTools} alt="tools" />
                </div>
                <div className={styles.desktopTools}>
                  <img  src={DesktopTools} alt="tools" />
                </div>
            </div>
            <div>
            <CarouselComponent />
            </div>
        </div>
    )
}

export default Expertise;