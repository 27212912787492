import React from "react";
import { PatternOne } from "../../images";
import styles from "./styles.module.css";

function index({ Paragraph, title, blogHeading, style }) {
  return (
    <div className={styles.aiWelcomeHeadingWrapper} style={style}>
      <div className={styles.innerTextWrapper}>
        <div className={styles.aiHeadingWrapper}>
          <div className={styles.aiHeadingContent}>
            <p>{blogHeading}</p>
            <h1> {title}</h1>
          </div>
          <img src={PatternOne} alt="Dots" />
        </div>
        <p>{Paragraph}</p>
      </div>
    </div>
  );
}

export default index;
