import { useState } from "react";
import { useParams, Link } from "react-router-dom";


import {
  SolutionCards,
  SolutionsBanner,
  WelcomeHeading,
  Modal,
} from "../../components";

import styles from "./styles.module.css";

import { solutions } from "../../data-constants/solutions";

function Solutions() {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [selectedSolution, setSelectedSolution] = useState();
  const { domain } = useParams();

  let currentSolution = solutions.find((sol) => sol.domain === domain);
  return (
    <>
      <div className={styles.bannerStyles}>
        <SolutionsBanner
          heading={currentSolution.heading}
          solutionContent={currentSolution.description}
        />
        <div className={styles.solutionsTilesWrapper}>
          <Link to="/solutions/healthcare"><div className={domain ==="healthcare" ? `${styles.solutionTile} ${styles.activeSolutionTile}` : styles.solutionTile}>Healthcare</div></Link>
          <Link to="/solutions/banking"><div className={domain ==="banking" ? `${styles.solutionTile} ${styles.activeSolutionTile}` : styles.solutionTile}>Banking</div></Link>
          <Link to="/solutions/other"><div className={domain ==="other" ? `${styles.solutionTile} ${styles.activeSolutionTile}` : styles.solutionTile}>Other</div></Link>
        </div>
      </div>
      <WelcomeHeading title={currentSolution.title} />
      <div className={styles.CardsWrapper}>
        <div className={styles.CardWrapper}>
          {currentSolution &&
            currentSolution.cards.map((sol) => (
              <>
                <SolutionCards
                  cardImg={sol.image}
                  cardInfo={sol.info}
                  key={sol.id}
                  onClick={() => {
                    setModalVisibility(!isModalVisible);
                    setSelectedSolution(sol);
                  }}
                />
              </>
            ))}
          {selectedSolution && (
            <Modal
              isModalVisible={isModalVisible}
              setModalVisibility={setModalVisibility}
            >
              <div>
                <div className={styles.ModalContent}>
                  <h1>{selectedSolution.info}</h1>
                  {selectedSolution.extraInfo && (
                    <p dangerouslySetInnerHTML={{
                      __html: selectedSolution.extraInfo,
                    }}/>
                  )}
                  <br />
                  {selectedSolution.challenge && (
                    <>
                      {" "}
                      <h4>Challenge</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: selectedSolution.challenge,
                        }}
                      />
                      <br />
                    </>
                  )}
                  {selectedSolution.solution && (
                    <>
                      <h4>Solution</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: selectedSolution.solution,
                        }}
                      />
                      <br />
                    </>
                  )}
                  {selectedSolution.results && (
                    <>
                      <h4>Result</h4>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: selectedSolution.results,
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
}
export default Solutions;
