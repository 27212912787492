import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { NavigationContext } from "../../contexts/navigation-context";
import {
  CloseBtn,
  Facebook,
  Instagram,
  LinkedIn,
  Logo,
  Twitter,
  Youtube,
} from "../../images";
import styles from "./styles.module.css";

function MenuContainer() {
  const { isNavigatorVisible, setNavigatorVisibility } =
    useContext(NavigationContext);

  return (
    <Drawer
      open={isNavigatorVisible}
      // className={styles.menuStyles}
      placement="right"
      height={"100vh"}
      width={"100vw"}
      handler={false}
      levelMove="0%"
      // afterVisibleChange={true}
      duration={100}
    >
      <div className={styles.menuStyles}>
        <div className={styles.logoAndCloseIcon}>
          <Link
            to="/"
            onClick={() => setNavigatorVisibility(!isNavigatorVisible)}
          >
            <div className={styles.logoWrapper}>
              <img src={Logo} alt="logo" />
              {/* <h4>Aizoh</h4> */}
            </div>
          </Link>
          <img
            src={CloseBtn}
            alt="close-icon"
            onClick={() => setNavigatorVisibility(!isNavigatorVisible)}
          />
        </div>
        <div className={styles.menuWrapper}>
          <div className={styles.MenuItemsWrapper}>
            <div className={styles.MenuItems}>
              <div className={styles.accordionStyles}>
                <Accordion
                  className={styles.accordion}
                  allowZeroExpanded={true}
                >
                  <AccordionItem className={styles.accItem}>
                    <AccordionItemHeading className={styles.accHeading}>
                      <AccordionItemButton className={styles.accBtn}>
                        About us
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={styles.accPanel}>
                      <Link
                        to="/mission-vision"
                        onClick={() =>
                          setNavigatorVisibility(!isNavigatorVisible)
                        }
                      >
                        <AccordionItemButton className={styles.accBtnInner}>
                          Mission & Vision
                        </AccordionItemButton>
                      </Link>
                      <Link
                        to="/who-we-are"
                        onClick={() =>
                          setNavigatorVisibility(!isNavigatorVisible)
                        }
                      >
                        <AccordionItemButton className={styles.accBtnInner}>
                          Who we are?
                        </AccordionItemButton>
                      </Link>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className={styles.accItem}>
                    <AccordionItemHeading className={styles.accHeading}>
                      <AccordionItemButton className={styles.accBtn}>
                        Our Work
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={styles.accPanel}>
                      <Link
                        to="/what-we-do"
                        onClick={() =>
                          setNavigatorVisibility(!isNavigatorVisible)
                        }
                      >
                        <AccordionItemButton className={styles.accBtnInner}>
                          What we do?
                        </AccordionItemButton>
                      </Link>
                      <Link
                        to="/our-projects"
                        onClick={() =>
                          setNavigatorVisibility(!isNavigatorVisible)
                        }
                      >
                        <AccordionItemButton className={styles.accBtnInner}>
                          Our projects
                        </AccordionItemButton>
                      </Link>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className={styles.accItem}>
                    <AccordionItemHeading className={styles.accHeading}>
                      <AccordionItemButton className={styles.accBtn}>
                        Our Ventures
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={styles.accPanel}>
                      <Link
                        to="/venture/ai-eye"
                        onClick={() =>
                          setNavigatorVisibility(!isNavigatorVisible)
                        }
                      >
                        <AccordionItemButton className={styles.accBtnInner}>
                          Ai Eye
                        </AccordionItemButton>
                      </Link>
                      <Link
                        to="/venture/xplorbee"
                        onClick={() =>
                          setNavigatorVisibility(!isNavigatorVisible)
                        }
                      >
                        <AccordionItemButton className={styles.accBtnInner}>
                          Xplor Bee
                        </AccordionItemButton>
                      </Link>
                      <Link
                        to="/venture/recapte"
                        onClick={() =>
                          setNavigatorVisibility(!isNavigatorVisible)
                        }
                      >
                        <AccordionItemButton className={styles.accBtnInner}>
                          Recapte
                        </AccordionItemButton>
                      </Link>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className={styles.accItem}>
                    <AccordionItemHeading className={styles.accHeading}>
                      <AccordionItemButton className={styles.accBtn}>
                        Newsroom
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={styles.accPanel}>
                      <Link
                        to="/latest-news"
                        onClick={() =>
                          setNavigatorVisibility(!isNavigatorVisible)
                        }
                      >
                        <AccordionItemButton className={styles.accBtnInner}>
                          Latest News - AI
                        </AccordionItemButton>
                      </Link>
                      <Link
                        to="/news&events"
                        onClick={() =>
                          setNavigatorVisibility(!isNavigatorVisible)
                        }
                      >
                        <AccordionItemButton className={styles.accBtnInner}>
                          Our News & Events
                        </AccordionItemButton>
                      </Link>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className={styles.accItem}>
                    <Link
                      to="/contact"
                      onClick={() =>
                        setNavigatorVisibility(!isNavigatorVisible)
                      }
                    >
                      <AccordionItemHeading className={styles.accHeading}>
                        <AccordionItemButton className={styles.accBtn}>
                          Contact Us
                        </AccordionItemButton>
                      </AccordionItemHeading>
                    </Link>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
            <div className={styles.mailSocialIcons}>
              {/* <h1>
                <a href="mailto: info@aizoh.com" target="_blank">
                  info@aizoh.com
                </a>
              </h1> */}
              <p>Follow Us</p>
              <div className={styles.iconStyles}>
                <a
                  href="https://www.facebook.com/Aizoh-110089837911891"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Facebook} alt="Facebook logo" />
                </a>
                <a
                  href="https://www.instagram.com/ai_zoh/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Instagram} alt="Instagram logo" />
                </a>
                <a
                  href="https://twitter.com/_aizoh"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Twitter} alt="Instagram logo" />
                </a>
                <a
                  href="https://www.linkedin.com/company/aizoh"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={LinkedIn} alt="LinkedIn logo" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCUe5B3GKT9eKDfYJocyTweQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Youtube} alt="Youtube logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default MenuContainer;
