import styles from "./styles.module.css";

function ContactInfo() {
  return (
    <div className={styles.ContactInfoWrapper}>
      <div className={styles.contactAddress}>
        <div className={styles.addressLinks}>
          <h3>aizoh-ai</h3>
          <div className={styles.addressWrapper}>
            {" "}
            <div className={styles.address}>
              <p>TFG Group,</p>
              <p> #9, Jafza 16, P.O 16764, Jebal Ali, </p>
              <p>Dubai, United Arab Emirates</p>
            </div>
            <div className={styles.phoneNumbers}>
              {" "}
              <p>Tel: +971 4 8819776 / +971 52 7248777 </p>
              <p>Fax: +971 4 881 9443</p>
            </div>
          </div>
          <h4>
            email:
            <a href="mailto: info@aizoh-ai.com" target="_blank" rel="noreferrer">
              info@aizoh-ai.com
            </a>
          </h4>
          {/* <h3>
            aizoh-ai, <br />
            Head Quarters- Office – 2101, Jafza View 2 Jabel Ali Dubai UAE
          </h3>
          <p>
            Offie- <a href="#">+971-4 </a>
          </p>
          <p>
            Fax- <a href="#">+971-4</a>
          </p>
          <h4>Our other International Office</h4>
          <p>
            Tokyo-
            <a href="mailto: infotokyo@afl-ai.com" target="_blank">
              infotokyo@afl-ai.com
            </a>
          </p>
          <p>
            Bangalore-
            <a href="mailto: infotokyo@afl-ai.com" target="_blank">
              infobangalore@afl-ai.com
            </a>
          </p>
          <p>
            Paris –
            <a href="mailto: infotokyo@afl-ai.com" target="_blank">
              infoparis@afla-ai.com
            </a>
          </p> */}
        </div>
      </div>
      <hr />
      <div className={styles.contactAddress}>
        <div className={styles.addressLinks}>
          <h3>
            Global presence
          </h3>
          <div className={styles.addressWrapper}>
            <div className={styles.address}>
              <p style={{ textDecoration: "underline" }}>
                Research and Development Centre <br /> Bangalore, India
              </p>
              <p>
                #64 Ground Floor-9th B cross,
                <br />
                Viratnagar, Bangalore - 560068, India
              </p>
              <p>
                Tel - +91-9901450829 <br />
                email: info@aizoh-ai.com
              </p>
            </div>
            <div className={styles.phoneNumbers}>
              {" "}
              <p style={{ textDecoration: "underline" }}>Tokyo, Japan</p>
              <p>
                Kanaya Bldg 4F, 4-11-3,
                <br />
                Hatchobori Chuo-ku, 104-0032,
                <br />
                Tokyo, Japan
              </p>
              <p>
                Tel: +81-3-3552-5277
                <br />
                email: info@aizoh-ai.com
                {/* <a href="mailto: info@aizoh-ai.com" target="_blank" rel="noreferrer"> */}
                {/* </a> */}
              </p>
            </div>
          </div>
          {/* <h4>
            email:
            <a href="mailto: info@aizoh-ai.com" target="_blank" rel="noreferrer">
              info@aizoh-ai.com
            </a>
          </h4> */}
          {/* <h3>
            aizoh-ai, <br />
            Head Quarters- Office – 2101, Jafza View 2 Jabel Ali Dubai UAE
          </h3>
          <p>
            Offie- <a href="#">+971-4 </a>
          </p>
          <p>
            Fax- <a href="#">+971-4</a>
          </p>
          <h4>Our other International Office</h4>
          <p>
            Tokyo-
            <a href="mailto: infotokyo@afl-ai.com" target="_blank">
              infotokyo@afl-ai.com
            </a>
          </p>
          <p>
            Bangalore-
            <a href="mailto: infotokyo@afl-ai.com" target="_blank">
              infobangalore@afl-ai.com
            </a>
          </p>
          <p>
            Paris –
            <a href="mailto: infotokyo@afl-ai.com" target="_blank">
              infoparis@afla-ai.com
            </a>
          </p> */}
        </div>
      </div>
    </div>
  );
}
export default ContactInfo;
