import { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from 'react-modal-video'
import { EventsCarousel } from "../../components";

import DefaultButton from "../../components/defaultButton/index";
import {
  ArrowRight,
  VideoIcon,
  ExploreAnts,
  SmartProduct,
  SkillsBuilding,
  AihealthCare,
  AiData,
  XplorBeeTile,
  RecapteTile,
  AieyeTile
  // allImages,
} from "../../images/index";

import styles from "./styles.module.css";

function HomePage({ history }) {
  const [isVideoModalVisible, setVideoModalVisiblity] = useState(false)
  // useEffect(() => {
  //   allImages.forEach((image) => {
  //     const newImage = new Image();
  //     newImage.src = image;
  //     window[image] = newImage;
  //   });
  // }, []);

  return (
    <div className={styles.homeStyles}>
      <div className={styles.mainWrapper}>
        <div className={styles.homebanner}>
          <div className={styles.contentStyle}>
            <h1>Aizoh</h1>
            <p>
              We are a people-led data and analytical strategy consultancy which
              creates data guided organisations.
            </p>
            <div className={styles.someInfo}>
              <span>
                <Link to="/about">
                  Read More <img src={ArrowRight} alt="arrow" />
                </Link>
              </span>
              {/* <a href="" > */}
                {/* <img src={VideoIcon} alt="pause-icon"  onClick={() => setVideoModalVisiblity(!isVideoModalVisible)}/> */}
              {/* </a> */}
            </div>
          </div>
        </div>
        <div className={styles.mainCards}>
          <div
            className={styles.card}
            onClick={() => history.push("/AI-and-data-science")}
          >
            <img src={AiData} alt="Ai-brain" />
            <h2>AI & Data Science</h2>
          </div>
          <div
            className={styles.card}
            onClick={() => history.push("/innovation-lab")}
          >
            <img src={ExploreAnts} alt="Ants" />
            <h2>Innovation Lab</h2>
          </div>
          <div
            className={styles.card}
            onClick={() => history.push("/smart-products")}
          >
            <img src={SmartProduct} alt="smart" />
            <h2>Smart Products</h2>
          </div>
          <div
            className={styles.card}
            onClick={() => history.push("/workshops-and-events")}
          >
            <img src={SkillsBuilding} alt="ai-brain-touch" />
            <h2>Workshops & Events</h2>
          </div>
        </div>
        <div className={styles.discoverContent}>
          <p>
            We change the way people view, access and use data to make better
            decisions.
          </p>
          <Link to="/what-we-do">
            <DefaultButton btnName={"More Info"} />
          </Link>
        </div>
        {/* <div className={styles.futureContent}>
          <h1>A Better Future</h1>
          <p>
            Aizoh is an analytics company that aims at solving business problems
            utilizing the power of cognitive AI and cloud infrastructure. We at
            Aizoh help clean and explore the unstructured data to bring forth the
            insights in it. These insights will help you make data-driven
            strategies that will give you an edge over your competitors.
          </p>
          <Link to="/about">
            <DefaultButton btnName={"About Us"} />
          </Link>
          <img src={PatternTwo} alt="dots-2" />
        </div> */}
        <div className={styles.featured}>
          <h1>
            Our Ventures <img src={ArrowRight} alt="arrow" />
          </h1>
          <div className={styles.venturesWrapper}>
            <img src={AieyeTile} alt="ai eye tile" className={styles.ovTile} onClick={() => history.push("/venture/ai-eye")}/>
            <img src={XplorBeeTile} alt="xplor bee tile" className={styles.ovTile} onClick={() => history.push("/venture/xplorbee")}/>
            <img src={RecapteTile} alt="recapte tile" className={styles.ovTile} onClick={() => history.push("/venture/recapte")}/>
          </div>
        </div>
        <div className={styles.featured}>
          <h1>
            Our Projects <img src={ArrowRight} alt="arrow" />
          </h1>
          <div className={styles.featuredWrapper}>
            <img src={AihealthCare} alt="Ai-healthCare" />
            <div className={styles.ReadMoreWork}>
              <p>
                <span>Artificial Intelligence in Healthcare Industry:</span> The
                Haemodialysis Patients with Cramps Initiative
              </p>
              <Link to="/our-projects">
                <DefaultButton btnName={"More Info"} />
              </Link>
            </div>
          </div>
        </div>   
        <EventsCarousel />
      </div>
      <ModalVideo channel='youtube' isOpen={isVideoModalVisible} videoId='zYKYjIdoXYA' onClose={() => setVideoModalVisiblity(!isVideoModalVisible)} />
    </div>
  );
}

export default HomePage;
