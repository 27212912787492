import { SectionsMenu, WelcomeHeading } from "../../components";
import { productsDesktop } from "../../images";
import styles from "./styles.module.css";

export default function smartProducts({ history }) {
  return (
    <div className={styles.productsWrapper}>
      <WelcomeHeading
        title={"Smart People Change for a better Life"}
        Paragraph={
          "Aizoh collaborate with manufactures and patent owners to develop, co-develop and introduce their smart devices  to various international markets."
        }
        style={{ paddingBottom: "30px" }}
      />
      <div style={{ marginBottom: "20px" }}>
        <SectionsMenu />
      </div>
      <div className={styles.productsGrid}>
        <img src={productsDesktop} alt="products" className={styles.product} />
      </div>
      {/* <div className={styles.contactWrapper}>
        <div className={styles.contactUs}>
          <p>For more information about our Smart Products</p>
          <button onClick={() => history.push("./contact")}>Contact Us</button>
        </div>
      </div> */}
    </div>
  );
}
