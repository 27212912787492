import React from "react";
import { FacebookLogo, LinkedInLogo, TwitterLogo } from "../../images";
import styles from "./index.module.css";

function UpcomingPeople({
  profileImg,
  profName,
  occ,
  locatedAt,
  fbLink,
  linkedInLink,
  twiiterLink,
  style,
}) {
  return (
    <div className={styles.cardStyles}>
      <div className={styles.cardContentWrapper} style={style}>
        <img src={profileImg} alt="profile" />
        <div className={styles.occWrapper}>
          <div className={styles.occupation}>
            <p>{profName}</p>
            <p>{occ}</p>
            <p>{locatedAt}</p>
          </div>
          <div className={styles.socialLinks}>
            <a href={linkedInLink}>
              <img src={LinkedInLogo} alt="linkedIn" />
            </a>
            <a href={fbLink}>
              <img src={FacebookLogo} alt="facebook" />
            </a>
            <a href={twiiterLink}>
              <img src={TwitterLogo} alt="twitter" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpcomingPeople;
