import React from "react";
import styles from "./styles.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

function CarouselComponent() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.carouselWrapper}>
        <Carousel
          additionalTransfrom={0}
          centerMode={false}
          arrows={true}
          swipeable={true}
          renderButtonGroupOutside={false}
          dotListClass={styles.dotStyles}
          responsive={responsive}
          infinite={true}
          autoPlay={true}
        >
          <Link to="/solutions/healthcare">
            <div className={styles.carouselText1}>
              <h1>Healthcare Data Analytics</h1>
              <h3>
                From acquisation of data to diagnosis of disease, a complete
                study for medical industry.
              </h3>
            </div>
          </Link>
          <Link to="/solutions/banking">
            <div className={styles.carouselText3}>
              <h1>Banking Data Analytics</h1>
              <h3>
                Database Resource Management and integrating the Artificial
                Interlligence (AI) Chatbots to ease the jobs
              </h3>
            </div>
          </Link>
          <Link to="/solutions/other">
            <div className={styles.carouselText2}>
              <h1>Other Solutions</h1>
              <h3>
                Revolutionizing the Energy Sector with the power of data
                analytics and solving complex problems.
              </h3>
            </div>
          </Link>
        </Carousel>
      </div>
    </div>
  );
}

export default CarouselComponent;
