import styles from "./styles.module.css";

function index() {
  return (
    <div className={styles.privacyWrapper}>
      <div className={styles.privacyBg}></div>
      <div className={styles.privacyContent}>
        <h1>Privacy Policy</h1>
      </div>
      <div className={styles.mainStyles}>
        <div className={styles.mainContent}>
          <h2>Privacy & Cookies Policy</h2>
          <p>
            This Policy provides an overview of how Aizoh Holding Limited,
            together with its subsidiaries (collectively hereinafter referred to
            as ” Aizoh”), collects your personal data, how it handles it, and
            how it protects your privacy.
          </p>
        </div>
        <div className={styles.mainContent}>
          <h3>Your personal data controller</h3>
          <p>
            Aizoh is a group of companies that act as joint controllers of your
            personal data. It means that the companies of the group jointly
            determine the purposes and means of personal data processing.
            <br />
            Aizoh data protection officer can be contacted at
            info[at]aizoh.com
          </p>
        </div>
        <div className={styles.mainContent}>
          <h3>Personal information we collect and use</h3>
          <p>
            Aizoh may collect your personal data that you provide to it, for
            example, when you contact Aizoh electronically or respond to an
            email from Aizoh. Aizoh may collect your business contact details
            when you exchange business cards with Aizoh representatives at
            meetings and events, or when you provide your cont acts to sign up
            for Aizoh events. When submitting your request/inquiry on Aizoh
            websites, you may be asked for information such as, but not limited
            to, your full name, email address, phone number, company you work
            for or own and/or country where you reside or work.
            <br />
            When contacting Aizoh through live chats on its websites, you may be
            asked for information such as, but not limited to, your name and
            email address. This is needed when we are not ready to answer you
            immediately and need more time to handle your request. When you give
            Aizoh your personal data in live chat, we assume that you consent to
            processing it by Aizoh. By using cookies, Aizoh may collect details
            of your visits to its websites such as, but not limited to, the
            pages you visited, the date, time and duration of your visit and/or
            your IP address as well as the browser you use and its version, your
            operating system and its version, your city and state.
            <br />
            It is Aizoh policy to collect only the minimum information required
            to handle and complete your request/inquiry or to reach other
            purposes for which Aizoh processes your personal data. If you
            believe Aizoh websites have collected excessive information about
            you, please contact Aizoh through the request/inquiry form to raise
            your concerns.
            <br />
            Aizoh does not intend to collect sensitive information through its
            websites unless it is legally required to do so. Examples of
            sensitive information include race or ethnic origin, political
            opinions, religious or philosophical beliefs, trade union
            membership, physical or mental health, genetic data, biometric data,
            sexual life or sexual orientation, and criminal records. Aizoh asks
            that you do not provide sensitive data of such nature when using its
            websites. When you submit your request/inquiry on Aizoh websites,
            Aizoh uses your data on the basis of your consent. In other cases,
            when you voluntarily exchange business contact details with Aizoh,
            Aizoh sends you information or marketing communications on the basis
            of its legitimate interests in maintaining a business relationship
            with you or companies you work for or represent and/or on the basis
            of your consent (when it is technically possible to collect your
            freely given, specific, informed and unambiguous consent). Cookies
            are used on the basis of your consent and/or Aizoh legitimate
            interest described below.
          </p>
        </div>
        <div className={styles.mainContent}>
          <h3>
            Purposes for which <span>Aizoh</span> uses your personal data
          </h3>
          <p>
            Aizoh may use your data to interact with you and/or respond to your
            requests/inquiries, to negotiate, perform or facilitate a contract
            with you or related to you, to send you invitations to events it
            organizes, to share with you information you might be interested in
            and/or to inform you about its services. Aizoh may also use your
            personal data to comply with law, regulations, public authority
            requests or court orders. These are examples of purposes for which
            Aizoh may use personal data you provide to it:
          </p>
          <ul>
            <li>to administer and manage Aizoh websites;</li>
            <li>
              to understand how people use the features and functions of its
              websites in order to improve user experience;
            </li>
            <li>
              to determine the company, organisation, institution, or agency
              that you work for or represent or with which you are otherwise
              associated;
            </li>
            <li>to develop its businesses and services;</li>
            <li>to analyze the usage of Aizoh websites;</li>
            <li>
              any other purposes for which you provided information to Aizoh.
            </li>
          </ul>
          <p>
            Aizoh websites do not collect or compile personally identifying
            information for sale to third parties for consumer marketing
            purposes.
          </p>
        </div>
        <div className={styles.mainContent}>
          <h3>Cookies</h3>
          <p>
            Cookies are small text files that may be placed and stored on your
            computer/device allowing internet access when you visit Aizoh
            websites.
            <br />
            Aizoh uses cookies to collect your IP address and browsing
            information. Aizoh websites also remember your system and
            preferences. Cookies are placed on your computer/device only if you
            consent to it, unless cookies are necessary for strictly technical
            functionality of Aizoh websites.
            <br />
            In order to record and analyze how visitors use Aizoh websites as
            well as to improve visitors’ experience, Aizoh collects details of
            your visits by using Google Analytics. Google Analytics places and
            uses cookies that allow Aizoh to track website traffic and users’
            interaction with Aizoh websites.
            <br />
            Aizoh may use cookies to record your consents on its websites (e.g.,
            the consent to the use of cookies)
            <br />
            Aizoh uses a third-party provider Drift to enable and support live
            chats on its websites. Cookies in these chats allow Aizoh to see the
            pages you visit, the browser you use and its version, your operating
            system and its version, your IP address as well as your city and
            state. This technology helps Aizoh compile analytics to improve its
            websites and their functionality.
            <br />
            Aizoh uses cookies on the basis of its legitimate interest in
            ensuring the technical functionality of its websites. When Aizoh
            uses cookies to remember your choices or for analytics, the legal
            basis for the use is your consent. You can opt-out from analytics by
            disabling cookies through your browser settings
          </p>
          <p>
            Please note that if you disable or refuse cookies, Aizoh websites or
            their parts may become inaccessible, not function properly or you
            may not be able to use some of the interactive features of Aizoh
            websites.
          </p>
        </div>
        <div className={styles.mainContent}>
          <h3>Who may access your personal data</h3>
          <p>
            Aizoh may disclose your personal data to (1) its employees who are
            in contact with you or who are responsible for marketing, (2) other
            companies of Aizoh (some of them are located outside the European
            Union), (3) third-party service providers.
            <br />
            Statistical data about Aizoh websites’ visitors may be accessed by
            (1) Aizoh employees in marketing and/or IT departments who are
            responsible for analysing such data and for improving Aizoh
            websites, (2) other companies of Aizoh (some of them are located
            outside the European Union), (3) third-party service providers.
          </p>
        </div>
        <div className={styles.mainContent}>
          <h3>Transfers to third countries</h3>
          <p>
            Aizoh is a multinational group of companies. For this reason, your
            personal data may be transferred to and stored outside the country
            where you are located. This includes countries outside the European
            Economic Area with no adequate level of personal data protection
            (third countries).
            <br />
            As some of the countries where our offices are situated are not yet
            recognized by the European Commission as countries with an adequate
            level of personal data protection, these offices have access to
            personal data on the basis of instruments that cover the European
            Union requirements for the transfer of personal data to data
            controllers/processors outside the European Union (Standard
            Contractual Clauses approved by the European Commission). You may
            ask Aizoh to inspect copies of these clauses by contacting its data
            protection officer.
          </p>
        </div>
        <div className={styles.mainContent}>
          <h3>Retention of your personal data</h3>
          <p>
            Aizoh will retain your personal information in its systems only for
            as long as Aizoh needs it, given the purposes for which it was
            collected, or as required to do so by law.
          </p>
        </div>
        <div className={styles.mainContent}>
          <h3>Rights in relation to your personal data</h3>
          <p>
            Under your local laws and the European Union legislation, you may
            exercise the following rights by submitting your request/inquiry on
            Aizoh websites:
          </p>
          <ul>
            <li>right to require access to your personal data,</li>
            <li>right to require rectification of your personal data,</li>
            <li>right to require erasure of your personal data,</li>
            <li>
              right to withdraw your consent regarding processing of your
              personal data.
            </li>
          </ul>
          <p>
            In some cases, you may also have a right (1) to request restriction
            on processing of your personal data or (2) to object to processing
            of your personal data.
          </p>
          <p>
            There are cases when Aizoh can retain copies of the personal data
            you requested it to erase. They concern the necessity to protect
            legitimate interests of Aizoh or third parties, comply with
            governmental orders, resolve disputes, troubleshoot problems, or
            enforce any agreement you have formed with Aizoh
          </p>
        </div>
      </div>
    </div>
  );
}

export default index;
