import styles from "./style.module.css";
import NephrologyImage from "../../images/newsroom/ai-nephrology.svg";
import { Certificate, Slides } from "../../images";
function Nephrology() {
  return (
    <div className={styles.nephrologyWrapper}>
      <div className={styles.conference}>
        <div className={styles.conferenceWrapper}>
          <div className={styles.conferenceContent}>
            <h3>Participation in 2021 Conference</h3>
            <h1>Artificial Intelligence & Nephrology 2021</h1>
            <div className={styles.conferenceDate}>
              <p>February 11-12,</p>
              <p>2021 100% Digital</p>
              <p>First Edition</p>
            </div>
          </div>
          <img src={NephrologyImage} alt="Nephrology" />
        </div>
      </div>
      <p>
        Mr. Abdul Samad Rahim, CEO and Founder of Aizoh attended the
        Conference on Artificial Intelligence & Nephrology 2021 and presented
        his talk on Artificial Intelligence and Big Data: A Key to Medical
        Revolution
      </p>

      <div className={styles.certificates}>
        <img src={Slides} alt="" />
      </div>
      <p>
        Conference details can be found on
        <a href="https://targeting-ai.com/" target="_blank" rel="noreferrer">
          <span> https://targeting-ai.com/</span>
      </a>
      </p>
      <p>
        <a href="https://youtu.be/zYKYjIdoXYA" target="_blank" rel="noreferrer">
          Click here to watch
        </a>
      </p>
    </div>
  );
}

export default Nephrology;
