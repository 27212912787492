import styles from "./styles.module.css" ;


function Logos ({image, title}) {
    return (
        <div>
            <div className={styles.soloLogo}>
                  <div>
                    <img src={image} alt="loading.." />
                  </div>
                  <p>{title}</p>
              </div>
        </div>
    )
}

export default Logos;