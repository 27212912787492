import React from "react";
import { WelcomeHeading, SectionsMenu } from "../../components";
import {
  AIMLlearningDesktop,
  AIMLlearningMobile,
  Flowchart,
  ProcessAIDesktop,
  ProcessAIMobile,
} from "../../images";
import styles from "./styles.module.css";

function AIDataScience() {
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.contentWrapper}>
        <WelcomeHeading title="AI & Data Science" />
        <p>
          AI and Data Science aim at solving business problems through the use
          of AI and cloud infrastructure
        </p>

        
        <SectionsMenu />

        <div className={styles.mobile}>
          <img src={ProcessAIMobile} alt="who are we" />
        </div>
        <div className={styles.desktop}>
          <img src={ProcessAIDesktop} alt="who are we" />
        </div>
        <div className={styles.flowchart}>
          <img src={Flowchart} alt="" />
        </div>
        <WelcomeHeading
          title="AI & Machine Learning Applications"
          Paragraph="AI and Data Science aim at solving business problems through the use of AI and cloud infrastructure"
        />

        <div className={styles.mobile}>
          <img src={AIMLlearningMobile} alt="who are we" />
        </div>
        <div className={styles.desktopAI}>
          <img src={AIMLlearningDesktop} alt="who are we" />
        </div>
      </div>
    </div>
  );
}

export default AIDataScience;
