import { useState } from "react";
import {
  ArrowRight,
  NewsBannerDesktop,
  NewsBannerMobile,
  Roadmap,
  WriterIcon,
  GameChanger,
  SearchIcon,
  FB,
  Insta,
  Tweet,
  LIn,
  Yt,
  FutureAI,
  HistoryAI,
  CrackCancer,
  MachineMedicin,
} from "../../images";
import styles from "./styles.module.css";
function Insights() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState({message: "", error: "false"});
  const [loading, setLoading] = useState(false);

  const subscribe = async () => {
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
   if(!emailPattern.test(email)){
      setMessage({message: "Please enter a valid email", error: true});
      return;
   }
   let encodedEmail = encodeURI(email);

    try {
      setLoading(true);
      const subscribe = await fetch(
        `/.netlify/functions/subscribe?email=${encodedEmail}`
      );
      if (subscribe.status === 200) {
        setLoading(false);
        setMessage({ message: "Thanks for subscribing, Stay tuned...", error: false });
        setEmail("")
      }

      if (subscribe.status > 500) {
        throw new Error("something went wrong!");
      }
    } catch (e) {
      setLoading(false);
      setMessage({
        message: "Something went wrong! Please try again later.",
        error: true,
      });
    }

  }

  return (
    <div className={styles.insightsWrapper}>
      <img src={NewsBannerMobile} alt="" className={styles.mobile} />

      <img src={NewsBannerDesktop} alt="" className={styles.desktop} />
      <div className={styles.contentWrapper}>
        {/* cards div */}
        <div className={styles.cardWrapper}>
        <div className={styles.cards}>
            <img src={MachineMedicin} alt="" />
            <div className={styles.card}>
              <h4>
              Application of Machine Learning in Translational Medicine: Current Status and Future Opportunities Highlights
              </h4>
              <div className={styles.cardSubheading}>
                <img src={WriterIcon} alt="" />
                <p>Nadia Terranova, Karthik Venkatakrishnan and Lisa J. Benincosa</p>
              </div>
              <p>
              The exponential increase in our ability to harness multi-dimensional biological and clinical data from experimental to real-world settings has transformed pharmaceutical research and development in recent years, with increasing applications of artificial intelligence (AI) and machine learning (ML). Patient-centered iterative forward and reverse translation is at the heart of precision medicine discovery and development across the continuum from target validation to optimization of pharmacotherapy. Integration of advanced analytics into the practice of Translational Medicine is now a fundamental enabler to fully exploit information contained in diverse sources of big data sets such as “omics” data, as illustrated by deep characterizations of the genome, transcriptome, proteome, metabolome, microbiome, and exposome. In this commentary, we provide an overview of ML applications in drug discovery and development, aligned with the three strategic pillars of Translational Medicine (target, patient, dose) and offer perspectives on their potential to transform the science and practice of the discipline. Opportunities for integrating ML approaches into the discipline of Pharmacometrics are discussed and will revolutionize the practice of model-informed drug discovery and development. Finally, we posit that joint efforts of Clinical Pharmacology, Bioinformatics, and Biomarker Technology experts are vital in cross-functional team settings to realize the promise of AI/ML-enabled Translational and Precision Medicine.
              </p>
              <a
                href="https://link.springer.com/article/10.1208/s12248-021-00593-x"
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.readmore}>
                  <p>More Info</p>
                  <img src={ArrowRight} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className={styles.cards}>
            <img src={Roadmap} alt="" />
            <div className={styles.card}>
              <h4>Road Map for Business Model innovation</h4>
              <div className={styles.cardSubheading}>
                <img src={WriterIcon} alt="" />
                <p>Wiebke Reim , Josef Åström and Oliver Eriksson</p>
              </div>
              <ul>
                <li>
                  Understand AI and organizational capabilities needed for
                  digital transformation;
                </li>
                <li>
                  Understand current BM, potential for BMI, and business
                  ecosystem role;
                </li>
                <li>
                  Develop and refine capabilities needed to implement AI; and
                </li>
                <li>
                  Reach organizational acceptance and develop internal
                  competencies.
                </li>
              </ul>
              <a href="https://www.mdpi.com/2673-2688/1/2/11" target="_blank" rel="noreferrer">
                <div className={styles.readmore}>
                  <p>More Info</p>
                  <img src={ArrowRight} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className={styles.cards}>
            <img src={GameChanger} alt="" />
            <div className={styles.card}>
              <h4>Data science: a game changer for science and innovation</h4>
              <div className={styles.cardSubheading}>
                <img src={WriterIcon} alt="" />
                <p>Valerio Grossi, Fosca Giannotti, Dino Pedreschi, et. al.</p>
              </div>
              <p>
                Data science’s potential for disruptive innovation in science,
                industry, policy, and people’s lives. The services proposed by
                So BigData are aimed at using data science to understand the
                complexity of our contemporary, globally interconnected society.
              </p>
              <a
                href="https://link.springer.com/article/10.1007%2Fs41060-020-00240-2"
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.readmore}>
                  <p>More Info</p>
                  <img src={ArrowRight} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className={styles.cards}>
            <img src={FutureAI} alt="" />
            <div className={styles.card}>
              <h4>
                Artificial intelligence, the future of intelligence or the
                intelligence of the future
              </h4>
              <div className={styles.cardSubheading}>
                <img src={WriterIcon} alt="" />
                <p>Aymeric Philibert</p>
              </div>
              <p>
                Artificial intelligence (AI) involves implementing a number of
                techniques to enable machines to simulate human cognitive
                functions, mimic the functioning of the human brain, sometimes
                its logic, when it comes to answering a question, making
                decisions or anticipating an event.
              </p>
              <a
                href="https://www.jle.com/fr/revues/odf/e-docs/lintelligence_artificielle_avenir_de_lintelligence_ou_intelligence_de_lavenir__317340/article.phtml"
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.readmore}>
                  <p>More Info</p>
                  <img src={ArrowRight} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className={styles.cards}>
            <img src={HistoryAI} alt="" />
            <div className={styles.card}>
              <h4>History of artificial intelligence in medicine</h4>
              <div className={styles.cardSubheading}>
                <img src={WriterIcon} alt="" />
                <p>Vivek Kaul , Sarah Enslin , Seth A Gross </p>
              </div>
              <p>
                Artificial intelligence (AI) was first described in 1950;
                however, several limitations in early models prevented
                widespread acceptance and application to medicine. In the early
                2000s, many of these limitations were overcome by the advent of
                deep learning. Now that AI systems are capable of analyzing
                complex algorithms and self-learning, we enter a new age in
                medicine where AI can be applied to clinical practice through
                risk assessment models, improving diagnostic accuracy and
                workflow efficiency. This article presents a brief historical
                perspective on the evolution of AI over the last several decades
                and the introduction and development of AI in medicine in recent
                years. A brief summary of the major applications of AI in
                gastroenterology and endoscopy are also presented, which are
                reviewed in further detail by several other articles in this
                issue of Gastrointestinal Endoscopy.
              </p>
              <a
                href="https://www.giejournal.org/article/S0016-5107(20)34466-7/fulltext"
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.readmore}>
                  <p>More Info</p>
                  <img src={ArrowRight} alt="" />
                </div>
              </a>
            </div>
          </div>
          <div className={styles.cards}>
            <img src={CrackCancer} alt="" />
            <div className={styles.card}>
              <h4>
                Artificial Intelligence could ‘crack the language of cancer and
                Alzheimer's’
              </h4>
              <div className={styles.cardSubheading}>
                <img src={WriterIcon} alt="" />
                <p>Prof. Tuomas Knowles; Dr. Kadi Liis Saar et.al.</p>
              </div>
              <p>
                Professor Tuomas Knowles, lead author of the paper and a Fellow
                at St John’s College, said Bringing machine-learning technology
                into research into neurodegenerative diseases and cancer is an
                absolute game-changer".
              </p>
              <a
                href="https://www.joh.cam.ac.uk/artificial-intelligence-could-crack-language-cancer-and-alzheimers"
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.readmore}>
                  <p>More Info</p>
                  <img src={ArrowRight} alt="" />
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* search and icons */}
        <aside>
          <div className={styles.search}>
            <input placeholder="Search" />
            <button>
              <img src={SearchIcon} alt="" />
            </button>
          </div>
          <div className={styles.updates}>
            <h4>Latest AI Updates</h4>
            <p>
              <a
                href="https://link.springer.com/article/10.1208/s12248-021-00593-x"
                target="_blank"
                rel="noreferrer"
              >
                Application of Machine Learning in Translational Medicine: Current Status and Future Opportunities Highlights
              </a>
            </p>
            <p>
              <a href="https://www.mdpi.com/2673-2688/1/2/11" target="_blank" rel="noreferrer">
                Road Map for Business Model innovation
              </a>
            </p>
            <p>
              <a
                href="https://link.springer.com/article/10.1007%2Fs41060-020-00240-2"
                target="_blank"
                rel="noreferrer"
              >
                Data science: a game changer for science and innovation
              </a>
            </p>
            <p>
              <a
                href="https://www.jle.com/fr/revues/odf/e-docs/lintelligence_artificielle_avenir_de_lintelligence_ou_intelligence_de_lavenir__317340/article.phtml"
                target="_blank"
                rel="noreferrer"
              >
                Artificial intelligence, the future of intelligence or the
                intelligence of the future
              </a>
            </p>
            <p>
              <a
                href="https://www.giejournal.org/article/S0016-5107(20)34466-7/fulltext"
                target="_blank"
                rel="noreferrer"
              >
                History of artificial intelligence in medicine
              </a>
            </p>
            <p>
              <a
                href="https://www.joh.cam.ac.uk/artificial-intelligence-could-crack-language-cancer-and-alzheimers"
                target="_blank"
                rel="noreferrer"
              >
                Artificial Intelligence could ‘crack the language of cancer and
                Alzheimer's’
              </a>
            </p>
          </div>
          <div className={styles.follow}>
            <h4>Follow Us</h4>
            <div className={styles.icons}>
              <a
                href="https://www.facebook.com/Ai-for-Life-110089837911891"
                target="_blank"
                rel="noreferrer"
              >
                <img src={FB} alt="" />
              </a>
              <a href="https://www.instagram.com/aifor_life" target="_blank" rel="noreferrer">
                <img src={Insta} alt="" />
              </a>
              <a href="https://twitter.com/Ai_forLife" target="_blank" rel="noreferrer">
                <img src={Tweet} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/ai-forlife"
                target="_blank"
                rel="noreferrer"
              >
                <img src={LIn} alt="" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCmjl8c8en8UcT1cP2hUhxIA"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Yt} alt="" />
              </a>
            </div>
          </div>
          <div className={styles.subscribe}>
            <h4>Subscribe</h4>
            <div className={styles.inputStyles}>
              <input placeholder="Email Address" value={email} type="email" onChange={e => {setEmail(e.target.value); setMessage({message: "", error: false})}}/>
              <button onClick={subscribe} >{loading ? "Subscribing..." : "Subscribe"}</button>
              <p style={{color: message.error ? "red" : "#6007B1", fontSize: "14px", }}>{message.message}</p>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
}

export default Insights;
